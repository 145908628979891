<template>
  <Guest auth pageId="reset-password">
    <div class="container">
      <button class="reset back-arrow" v-on:click="goBack">
        <SvgInline path="icons/back-button.svg" />
      </button>

      <h1>Nieuw wachtwoord instellen</h1>

      <div v-if="error" class="error">
        <p>{{ error }}</p>
      </div>

      <form v-on:submit.prevent="submitNewPassword">
        <div class="input-group">
          <input class="input-text input-text" type="password" required name="password" placeholder="Nieuw wachtwoord *" v-model="password">
        </div>

        <div class="input-group">
          <input class="input-text input-text" type="password" required name="password-repeat" placeholder="Nieuw wachtwoord herhalen *" v-model="passwordRepeat">
        </div>

        <button type="submit" class="btn btn-color-pink">Verzend</button>
      </form>
    </div>
  </Guest>
</template>

<script>

import Guest from "@/layouts/Guest";
import axios from "axios";
import {baseUrl} from "@/store/api";
import SvgInline from "@/components/SvgInline";

export default {
  name: "SetNewPassword",
  components :  { Guest, SvgInline },

  data() {
    return {
      password : null,
      passwordRepeat : null,
      error : null
    }
  },
  methods : {
    async submitNewPassword() {
      const code = this.$route.query.code;
      const id = this.$route.query.id;

      if (!this.checkPasswords()) {
        this.password = null;
        this.passwordRepeat = null;

        return this.error = 'Wachtwoorden komen niet overeen';
      }

      if(!code || !id) {
        return this.error = 'De resetcode is verplicht of onjuist.';
      }

      await axios.post(baseUrl('set-new-password'), {password: this.password, code : code, id : id})
          .then(() => {
            this.$store.dispatch('setSuccessMessage', 'Nieuw wachtwoord succesvol ingesteld');
            this.$router.push({name : 'login'});
          })
          .catch(error => {
            this.error = error.response.data.error;
          })
    },
    checkPasswords() {
      return this.password === this.passwordRepeat;
    },
    goBack() {
      this.$router.push({name : 'account'});
    },
  }

}
</script>