<template>
  <Authenticated pageId="game" v-if="game" origin class-name="detail">
    <div class="container pb-0">
      <div class="slider">
        <swiper :slides-per-view="1" class="header-img">
          <swiper-slide v-for="media in game.media" :key="`media_${media.id}`">
            <div class="img-container">
              <picture>
                <source :srcset="media.webp" type="image/webp">
                <source :srcset="media.jpg" type="image/jpg">
                <img class="banner" :src="media.webp" :alt="`FOS Open Scouting : ${game.title}`">
              </picture>
            </div>
          </swiper-slide>
        </swiper>

        <Actions :subject="game" />
      </div>
    </div>

      <div class="container border-bottom">
        <div class="meta">
          <h1>{{ game.title }}</h1>

          <GameProperties :properties="game.properties" detail/>

          <b v-if="game.theme" class="theme">{{ game.theme.title }}</b>

          <ul class="tags">
            <li
                v-for="tag in game.tags"
                :key="tag.id">
              <span>#{{ tag.title }}</span>
            </li>
          </ul>

          <p class="author">
            Auteur: <router-link :to="{name : 'volunteer'}">{{ game.author ?? 'geen auteur' }}</router-link>
          </p>
          </div>
      </div>


      <nav class="navigator game-tab">
        <button v-on:click="tab = 'info'" :class="{ 'active' : tab === 'info'}" class="reset">Het spel</button>
        <button v-on:click="tab = 'material'" :class="{ 'active' : tab === 'material'}" class="reset">Materiaal
        </button>
        <button v-on:click="tab = 'preparation'" :class="{ 'active' : tab === 'preparation'}" class="reset">
          Voorbereiding
        </button>
      </nav>


    <div class="body detail-content">

      <template v-if="tab === 'info'">
        <div v-if="game.summary" class="summary no-border">
          <p>{{ game.summary }}</p>
        </div>

        <div class="article-content">
          <div v-if=game.content.story class="story" v-html="game.content.story"></div>
          <div v-if="game.content.flow" class="flow" v-html="game.content.flow"></div>
        </div>
      </template>

      <div v-if="tab === 'material'" class="article-content material">
        <div v-html="game.content.material"></div>
        <div class="article-content" v-if="game.content.downloads.length > 0">
            <div class="file" v-for="(download, i) in game.content.downloads" :key="`download$-${i}`">
              <a download target="_blank" :href="download.url">
                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z"/><path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z"/></svg>
                <span>{{ download.name }}</span>
              </a>
            </div>
        </div>
      </div>
      <div v-if="tab === 'preparation'" class="article-content preparation"><div class="wrap" v-html="game.content.preparation"></div></div>

    </div>

    <div class="content related-content container" v-if="related.length > 0">
      <h2>Meer zoals dit</h2>

      <ul class="related">
        <li v-for="game in related" :key="`related_${game.id}`">
          <GameItem :data="game" />
        </li>
      </ul>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "../layouts/Authenticated";
import Actions from "../molecules/Actions";
import {mapGetters} from "vuex";
import GameProperties from "../molecules/GameProperties";
import GameItem from "../organisms/GameItem";
export default {
  name: "SingleGame",
  components: {GameItem, GameProperties, Actions, Authenticated},
  data() {
    return {
      tab: 'info',
    }
  },
  props: {
    game: {type: Object, required: true}
  },
  computed : {
    ...mapGetters({
      relatedGames: 'games/related',
    }),
    related() {
      return this.relatedGames(this.game);
    }
  },
  mounted() {
    this.$store.dispatch('viewItem', this.game);
  },
  metaInfo(){
    return {
      title : this.game.title,
      meta : [
        { property : 'og:title' , content : this.game.seo.title },
        { property : 'og:description' , content : this.game.seo.description },
        { property : 'og:image' , content : this.game.seo.image },
        { property : 'og:locale' , content : 'nl_BE' },
        { property : 'og:type' , content : 'website' },
        { property : 'og:url' , content : window.location.href },
      ]
    }
  }
}
</script>
