<template>
  <form v-on:submit.prevent="proceed">
    <div class="container">
      <h1>Maak een account</h1>

      <div class="error" v-if="error" v-html="error"></div>

      <div class="input-group">
        <input class="input-text input-email" type="email" required name="email" placeholder="email"
               v-model="newUser.email">
      </div>

      <div class="input-group">
        <input
            class="input-text input-password"
            type="password"
            required
            name="password"
            placeholder="wachtwoord"
            v-model="newUser.password"
            v-on:change="checkPassword"
        >
      </div>

      <div class="input-group">
        <button type="submit" class="submit">
          <SvgInline path="icons/arrow-right.svg"/>
        </button>
      </div>

      <SocialLogin :type="'register'" />
    </div>
  </form>
</template>

<script>
import SvgInline from "../../components/SvgInline";
import axios from "axios";
import {baseUrl} from "@/store/api";
import SocialLogin from "@/components/SocialLogin";

export default {
  name: "RegistrationFormStep1",
  components: {SocialLogin, SvgInline},
  data() {
    return {
      step: 1,
      error: null,
      firstTry: true,
    }
  },
  emits: ['registration-process', 'update:modelValue'],
  props: {
    modelValue: {type: Object, required: true}
  },
  computed: {
    newUser: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    proceed() {
      axios.post(baseUrl('validate-credentials'), {email: this.newUser.email, password: this.newUser.password})
          .then(() => {
            this.$emit("registration-process");
          })
          .catch(error => {
            this.error = error.response.data.error;
          })
    },
    checkPassword() {
      if (this.newUser.password.length < 8) {
        this.error = 'Wachtwoord moet uit 8 karakters of langer bestaan';
        this.firstTry = false;
      }
    }
  }
}
</script>
