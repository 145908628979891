<template>
  <div class="search-modal">
    <button class="reset exit" v-on:click="$store.dispatch('showSearchModal')">
      <SvgInline path="icons/exit.svg" />
    </button>

    <div class="container">
      <form class="search-form" v-on:submit.prevent="">
        <div class="search">
          <div class="search-field">
            <input v-model="search.searchTerm" type="text" placeholder="Zoeken" v-on:keyup.enter="submitSearch" ref="searchInput"/>
            <SvgInline path="icons/search.svg" v-on:click="null"/>
            <SvgInline
                v-show="search.searchTerm"
                path="icons/exit.svg"
                v-on:click="search.searchTerm = ''"
            />
          </div>
        </div>
      </form>

      <div class="show-options">
        <div class="container">
          <h2>Ik zoek een</h2>
          <ul>
            <li v-on:click="toggle('games', 'selectedGroup')"
                :class="{'active' :  search.selectedGroup.includes('games')}">spel,
            </li>
            <li v-on:click="toggle('articles', 'selectedGroup')"
                :class="{'active' :  search.selectedGroup.includes('articles')}">artikel
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class="themes">
      <h2>Populaire thema's</h2>
      <swiper-wrapper>
        <swiper-slide v-for="theme in themes" :key="`theme_${theme.id}`">
          <CategoryItem
              :theme="theme"
              v-on:toggle="toggle(theme.id, 'themeSearch')"
              :active="search.themeSearch.includes(theme.id) || search.themeSearch.length === 0"
          />
        </swiper-slide>
      </swiper-wrapper>
    </div>

    <div class="interest-options-as-tags">
      <div class="container">
        <ul class="tag-options">
          <li v-for="tag in tags" :key="`tag_${tag.id}`">
            <TagItem
                :tag="tag"
                v-on:toggle="toggle(tag.id, 'tagSearch')"
                :active="search.tagSearch.includes(tag.id) || search.tagSearch.length === 0"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="bottom-search">
      <button
          v-on:click="submitSearch"
          class="btn btn-color-light-red submit-filter-button">
        Toepassen
      </button>

      <button v-if="checkFields" id="reset-search-fields" class="reset reset-filter-button" v-on:click="resetFields">
        <SvgInline path="icons/exit.svg" />
        wis zoekvelden
      </button>
    </div>

    <nav class="bottom-navigation-search bottom-navigation">
      <button
          v-on:click="submitSearch"
          class="btn btn-color-light-red submit-filter-button">
        Toepassen
      </button>

      <button v-if="checkFields" id="reset-search-fields-mobile" class="reset reset-filter-button" v-on:click="resetFields">
        <SvgInline path="icons/exit.svg" />
        wis zoekvelden
      </button>

      <ul>
        <li>
          <button class="reset" v-on:click="goTo('map')">
            <SvgInline path="icons/home.svg" />
          </button>
        </li>
        <li>
          <button class="reset search active" v-on:click="$store.dispatch('showSearchModal')">
            <SvgInline path="icons/search.svg" />
          </button>
        </li>
        <li>
          <button class="reset" v-on:click="goTo('map')">
            <SvgInline path="icons/map.svg" />
          </button>
        </li>
      </ul>
    </nav>

  </div>

</template>

<script>
import SvgInline from "@/components/SvgInline";
import CategoryItem from "@/molecules/CategoryItem";
import {mapGetters} from "vuex";
import TagItem from "@/molecules/TagItem";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";

export default {
  name: "SearchModal",
  components: {TagItem, CategoryItem, SvgInline, SwiperWrapper, SwiperSlide},
  data() {
    return {
      windowWidth : 0,
      selectedGroup : {
        games : true,
        articles : false
      },
      search : {
        searchTerm : '',
        selectedGroup: [],
        themeSearch: [],
        tagSearch: [],
        groupSearch: [],
      }
    }
  },
  computed: {
    ...mapGetters({
      articles: 'articles/all',
      themes: 'themes/all',
      tags: 'tags/all',
    }),

    checkFields() {
      return this.search.searchTerm !== '' ||
          this.search.selectedGroup.length !== 0 ||
          this.search.themeSearch.length !== 0 ||
          this.search.tagSearch.length !== 0 ||
          this.search.groupSearch.length !== 0
    },
  },
  mounted() {
    this.search = this.$store.getters['search/search'];
    this.$refs.searchInput.focus();
  },
  methods : {
    resetFields() {
      this.search = {
        searchTerm: '',
        selectedGroup: [],
        themeSearch: [],
        tagSearch: [],
        groupSearch: [],
      }

      this.$store.dispatch('search/doSearch', this.search);
    },
    toggle(value, key) {
      const index = this.search[key].indexOf(value);

      if (index === -1) {
        this.search[key] = [...this.search[key], value];
      } else {
        this.search[key].splice(index, 1);
      }
    },
    submitSearch() {
      this.$store.dispatch('search/doSearch', this.search).then(() => {
        this.$store.dispatch('showSearchModal');
        this.$router.push({ name : 'search-results'});
      });
    },
    goTo(link){
      this.$store.dispatch('showSearchModal');
      this.$router.push({ name : link});
    }
  }
}
</script>
