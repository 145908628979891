<template>
  <Authenticated
      pageId="popular"
      withFilter
  >
    <div class="container">
      <h1 class="text-align-center">Populair</h1>
    </div>

    <swiper-wrapper>
      <swiper-slide v-for="theme in themes" :key="`theme_${theme.id}`">
        <CategoryItem
            :theme="theme"
            v-on:toggle="this.$store.dispatch('preferences/toggleThemeFilter', theme)"
            :active="themeFilter.includes(theme.id) || themeFilter.length === 0"
        />
      </swiper-slide>
    </swiper-wrapper>

    <div class="container">
      <div class="nav-content">
        <Navigator />
        <button v-if="this.appliedFilters" class="reset remove-filters" v-on:click="this.$store.dispatch('preferences/resetFilters')">
          <SvgInline path="icons/exit.svg" />
          actieve filters verwijderen
        </button>
      </div>


      <div class="content">

        <InfiniteScroll v-if="items.length > 0" :items="items">
          <template v-slot:item="{ item }">
            <ArticleItem v-if="item.type === 'article' || item.type === 'poll' || item.type === 'event'" :data="item" />
            <GameItem v-else :data="item" />
          </template>
        </InfiniteScroll>

        <div v-else class="no-results">
          Geen resultaten gevonden op basis van de huidige filters
        </div>

      </div>
    </div>

  </Authenticated>
</template>

<script>
import ArticleItem from "@/organisms/ArticleItem";
import Authenticated from "@/layouts/Authenticated";
import {mapGetters, mapState} from "vuex";
import GameItem from "@/organisms/GameItem";
import CategoryItem from "@/molecules/CategoryItem";
import Navigator from "@/components/layout/Navigator";
import InfiniteScroll from "@/molecules/InfiniteScroll";
import SvgInline from "@/components/SvgInline";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";


export default {
  name: "Popular",
  components: {SvgInline, InfiniteScroll, Navigator, CategoryItem, GameItem, Authenticated, ArticleItem, SwiperWrapper, SwiperSlide},
  data() {
    return {
      showAllTags : false,
      windowWidth : 0,
      desktopWidth : window.innerWidth,
    }
  },
  computed: {
    ...mapGetters({
      tags: 'tags/all',
      items: 'popularItems',

      themes: 'themes/all',
      themeFilter: 'preferences/themeFilter',
      activeTags: 'preferences/tagFilter'
    }),
    shownTags() {
      if (!this.showAllTags) {
        return this.tags.slice(0,3);
      }

      return this.tags;
    },

  },
  metaInfo() {
    return {
      title: 'Populair',
    }
  },
}
</script>
