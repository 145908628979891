<template>
  <article
      v-if="data.image"
      data-sal="zoom-in"
      data-sal-delay="0"
      data-sal-easing="ease-out"
      data-sal-duration="300"
      :class="[data.type, {'featured' : featured}]">
    <EventItem :hideActions="hideActions" v-if="data.type ==='event'" :data="data" />
    <PollItem :hideActions="hideActions" v-else-if="data.type ==='poll'" :data="data" />
    <a :href="`/artikelen/${data.slug}~${data.id}`" class="article-item">
      <div class="img-container">

        <div v-if="data.featured" class="label-featured">
          IN DE SPOTLIGHT
        </div>

        <picture v-if="data.image">
          <source :srcset="data.image.webp" type="image/webp">
          <source :srcset="data.image.jpg" type="image/jpg">
          <img class="thumb"
               :src="data.image.jpg"
               alt="fos open scouting scouts"
               :data-width="data.imageSize.width"
               :data-height="data.imageSize.height"
          />
        </picture>

        <div class="lazy" v-else></div>

        <div class="overlay-options">
          <Actions v-if="!hideActions" :subject="data" limited/>
        </div>
      </div>

      <div class="body">
        <h3>{{ data.title }}</h3>
        <span class="topic">{{ data.theme.title }}</span>

        <ul>
          <li v-for="tag in data.tags" :key="`tag-${tag.id}`">#{{ tag.title }}</li>
        </ul>
        <button class="save reset" :class="{'saved' : this.user.hasFavourited(this.data)}" v-if="!hideActions" v-on:click.prevent="togglePopup" >
          <SvgInline path="icons/save.svg" />
        </button>
      </div>

    </a>
  </article>
</template>

<script>
import Actions from "@/molecules/Actions";
import PollItem from "@/organisms/PollItem";
import SvgInline from "@/components/SvgInline";
import EventItem from "@/organisms/EventItem";

export default {
  name: "ArticleItem",
  components: {EventItem, SvgInline, PollItem, Actions},
  data() {
    return {
      showSavePopup : false,
    }
  },
  props : {
    data : { type : Object, required : true},
    hideActions: {type: Boolean, required: false, default: false},
    featured : { type : Boolean, required : false, default : false }
  },
  methods : {
    togglePopup() {
      this.authenticated ? this.toggleSavePopup() : this.toggleLoginPopup()
    },
    toggleLoginPopup() {
      this.$store.dispatch('showLoginPopup');
    },
    toggleSavePopup() {
      this.$store.dispatch('setItemToSave', this.data);
      this.$store.dispatch('showBoardPopup');
    }
  }
}
</script>
