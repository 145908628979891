<template>
  <inline-svg :src="require(`@/assets/images/${path}`).default"></inline-svg>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: "SvgInline",
  components : {
    InlineSvg
  },
  props : {
    path : String,
  }
}
</script>

<style scoped>

</style>

