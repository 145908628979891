<template>
  <div :id="pageId ?? null" class="page" :class="className">
    <Filter v-if="showFilter" />
    <SearchModal v-if="showSearchModal" />
    <Header :reset="reset" :with-filter="withFilter" :back-button="backButton" :origin="origin" />
    <slot/>
    <BottomNavigation v-if="!hideBottomNavigation" />
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import Filter from "@/components/layout/Filter";
import BottomNavigation from "@/components/layout/BottomNavigation";
import SearchModal from "@/components/layout/SearchModal";

export default {
  name: "Authenticated",
  components : {
    SearchModal,
    BottomNavigation,
    Header, Filter
  },
  props : {
    pageId : { type : String, required : false },
    withFilter: { type : Boolean, required : false, default: false },
    backButton: { type : Object, required : false},
    reset : { type : Boolean, required: false },
    origin: { type: Boolean, required : false },
    className : { type : String, required : false },
    hideBottomNavigation : { type : Boolean, required : false }
  },
  computed: {
    showFilter() {
      return this.$store.state.showFilter
    },
    showSearchModal() {
      return this.$store.state.showSearchModal
    }
  },

}
</script>
