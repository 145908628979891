<template>
  <Guest auth pageId="reset-password">
    <div class="container">
      <button class="reset back-arrow" v-on:click="goBack">
        <SvgInline path="icons/back-button.svg" />
      </button>

      <h1>Nieuw wachtwoord aanvragen</h1>

      <div v-if="error" class="error">
        <p>{{ error }}</p>
      </div>

      <form v-on:submit.prevent="submitResetPassword">
        <div class="input-group">
          <input class="input-text input-text" type="email" required name="email" placeholder="E-mailadres *" v-model="email" >
        </div>

        <button type="submit" class="btn btn-color-pink">Verzend</button>
      </form>
    </div>
  </Guest>
</template>

<script>

import Guest from "@/layouts/Guest";
import SvgInline from "@/components/SvgInline";
import axios from "axios";
import {apiUrl, baseUrl} from "@/store/api";

export default {
  name: "ResetPassword",
  components :  { Guest, SvgInline },
  data() {
    return {
      email : null,
      error : null
    }
  },
  methods : {
    goBack() {
      this.$router.push({name : 'account'});
    },
    submitResetPassword() {
      axios.post(baseUrl('reset-password'), {email: this.email})
          .then(() => {
            this.$store.dispatch('setSuccessMessage', 'Succes! We hebben je een mailtje gestuurd met de link om je nieuw wachtwoord aan te maken');
          })
          .catch(error => {
            this.error = error.response.data.error;
          })
    }
  }
}
</script>