import {createStore} from 'vuex';
import axios from "axios";
import user from "./user.js";
import articles from "./articles.js";
import themes from "./themes.js";
import groups from "./groups.js";
import tags from "./tags.js";
import preferences from "./preferences.js";
import games from "./games.js";
import pages from "./pages.js";
import search from "./search.js";
import {baseUrl} from "@/store/api";
import {emptyGame, Game} from "@/store/_classes";
import { get,set } from 'idb-keyval';

const store = createStore({
  state: {
    showFilter : false,
    showGameModal : false,
    showLoginPopup : false,
    showBoardPopup : false,
    showRemovePopup : false,
    showRemoveBoardPopup : false,
    showSearchModal : false,
    itemToSave : null,
    itemToRemove : null,
    boardToRemove : null,
    origin : {name: 'popular'},
    likedItems: [],
    sliderPositionCenter : true,
    groupSliderPositionCenter : true,
    successMessage : null,
    errorMessage : null,
    step : 0,
    newGame: emptyGame,
    usePosition: false,
    loadingState: false,
    pageLocation: {
      status: null,
      items: [],
    },
    loaded: false,
  },
  mutations: {
    hidePopups(state) {
      state.showFilter = false;
      state.showGameModal = false;
      state.showLoginPopup = false;
      state.showBoardPopup = false;
      state.showRemoveBoardPopup = false;
      state.showSearchModal = false;
    },
    usePosition(state) {
      state.usePosition = true;
    },
    removePosition(state) {
      state.usePosition = false;
    },
    setShowFilter(state) {
      state.showFilter = !state.showFilter;
    },
    setOrigin(state, origin) {
      const route = {...origin};
      delete route.matched;
      state.origin = route;
    },
    setPageLocation(state, location) {
      state.pageLocation = location;
    },
    setShowGameModal(state) {
      state.showGameModal = !state.showGameModal;
    },
    setShowSearchModal(state) {
      state.showSearchModal = !state.showSearchModal;
    },
    setShowLoginPopup(state) {
      state.showLoginPopup = !state.showLoginPopup;
    },
    setShowBoardPopup(state) {
      state.showBoardPopup = !state.showBoardPopup;
    },
    setShowRemovePopup(state) {
      state.showRemovePopup = !state.showRemovePopup;
    },
    setSowRemoveBoardPopup(state) {
      state.showRemoveBoardPopup = !state.showRemoveBoardPopup;
    },
    setLoadingState(state, loadingState) {
      state.loadingState = loadingState;
    },
    setItemToSave(state, item) {
      state.itemToSave = item;
    },
    setItemToRemove(state, item) {
      state.itemToRemove = item;
    },
    setBoardToRemove(state, board) {
      state.boardToRemove = board;
    },
    setSliderPositionCenter(state, center) {
      state.sliderPositionCenter = center;
    },
    setGroupSliderPositionCenter(state, center) {
      state.groupSliderPositionCenter = center;
    },
    updateNewGame(state, game) {
      state.newGame = game;
    },
    setStep(state, step) {
      state.step = step;
    },
    setSuccessMessage(state, message) {
      state.successMessage = message;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
  },
  getters: {
    getLoadingState: state => state.loadingState,
    sliderPositionCenter: state => state.sliderPositionCenter,
    showLoginPopup: state => state.showLoginPopup,
    showBoardPopup: state => state.showBoardPopup,
    showRemovePopup: state => state.showRemovePopup,
    showGameModal: state => state.showGameModal,
    getOrigin: state => state.origin,
    getPageLocation: state => state.pageLocation,
    showRemoveBoardPopup: state => state.showRemoveBoardPopup,
    itemToSave: state => state.itemToSave,
    itemToRemove: state => state.itemToRemove,
    boardToRemove: state => state.boardToRemove,
    showFilter: state => state.showFilter,
    showSearchModal : state => state.showSearchModal,
    groupSliderPositionCenter: state => state.groupSliderPositionCenter,
    successMessage : state => state.successMessage,
    errorMessage : state => state.errorMessage,
    newGame : state => state.newGame,
    step : state => state.step,
    gamesAndArticlesFiltered(state, getters, rootState) {
      let all = [];

      if (rootState.preferences.selectedGroup !== 'games') {
        all = [...all, ...getters['articles/filtered']('home')];
      }

      if (rootState.preferences.selectedGroup !== 'articles') {
        all = [...all, ...getters['games/filtered']('home')];
      }

      all = all.sort((a, b) => {
        const c = new Date(a.date);
        const d = new Date(b.date);
        return c - d;
      });

      return [
        ...all.filter(val => val.featured === true),
        ...all.filter(val => val.featured !== true),
      ];
    },
    gamesAndArticlesForYou(state, getters) {
      let all = [];

      all = [...all, ...getters['articles/forYou']];
      all = [...all, ...getters['games/forYou']];

      all = all.sort((a, b) => {
        const c = new Date(a.date);
        const d = new Date(b.date);
        return c - d;
      });

      return [
        ...all.filter(val => val.featured === true),
        ...all.filter(val => val.featured !== true),
      ];
    },
    searchedItems(state, getters) {
      return [...getters['articles/searched'](), ...getters['games/searched']()];
    },
    popularItems(state, getters) {
      const articles = getters['articles/filtered']('popular');
      let items = [
        ...articles,
        ...getters['games/filtered']('popular')
      ];

      items = items.filter(v => typeof v.views !== 'undefined').sort((a,b) => b.views - a.views);

      return [
        ...items.filter(val => val.featured === true),
        ...items.filter(val => val.featured !== true),
      ];
    }
  },
  actions: {
    hidePopups({commit}) {
      commit('hidePopups');
    },
    showFilter({ commit }) {
      commit('setShowFilter')
    },
    showSearchModal({ commit }) {
      commit('setShowSearchModal')
    },
    showGameModal({ commit }) {
      commit('setShowGameModal')
    },
    showLoginPopup({ commit }) {
      commit('setShowLoginPopup')
    },
    showBoardPopup({ commit }) {
      commit('setShowBoardPopup')
    },
    showRemovePopup({ commit }) {
      commit('setShowRemovePopup')
    },
    showRemoveBoardPopup({ commit }) {
      commit('setSowRemoveBoardPopup')
    },
    setItemToSave({commit}, item) {
      commit("setItemToSave", item);
    },
    setItemToRemove({commit}, item) {
      commit("setItemToRemove", item);
    },
    updateGame({commit}, game) {
      commit("updateNewGame", game);
    },
    setStep({commit}, step) {
      commit("setStep", step);
    },
    setLoadingState({commit}, loadingState) {
      commit("setLoadingState", loadingState);
    },
    setSuccessMessage({commit}, message) {
      commit("setSuccessMessage", message);
    },
    setErrorMessage({commit}, message) {
      commit("setErrorMessage", message);
    },
    setBoardToRemove({commit}, board) {
      commit("setBoardToRemove", board);
    },
    viewItem({ commit }, item) {
      return axios.post(baseUrl('view-item'), {item: item.id});
    },
    sliderPositionCenter({ commit }, center){
      commit('setSliderPositionCenter', center);
    },
    groupSliderPositionCenter({ commit }, center){
      commit('setGroupSliderPositionCenter', center);
    },
    setPageLocation({commit}, location) {
      commit('setPageLocation', location);
    },
    setOrigin({commit}, origin){
      commit('setOrigin', origin);
    },
    async initialiseStore({ state, dispatch }) {
      let promise = null;
      if (!state.loaded) {
        state.loaded = true;

        promise = Promise.all([
          dispatch('themes/fetch'),
          dispatch('tags/fetch'),
          dispatch('articles/fetch'),
          dispatch('games/fetch'),
          dispatch('groups/fetch'),
          dispatch('pages/fetch'),
        ]);
      }

      const store = await get('store');


      if (store) {
        const loadedState = JSON.parse(store);
        loadedState.loadingState = false;

        return this.replaceState(
          Object.assign(state, loadedState)
        );
      }

      return promise;
    }
  },
  modules: {
    user,
    articles,
    themes,
    groups,
    preferences,
    tags,
    games,
    pages,
    search
  },
});

store.subscribe((mutation, state) => {
  set('store', JSON.stringify(state));
});

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['X-CSRF-TOKEN'] = window.csrfTokenValue;

axios.interceptors.response.use((response) => response, (error) => {
  if (
    error.response.status === 401 && (
        error.response.data.error === 'De JWT kon niet gevonden worden.' ||
        error.response.data.error === 'Kon geen login informatie vinden.'
      )
    ) {
    return store.dispatch('user/logout');
  }

  if (error.response && error.response.data && error.response.data.error === 'Expired token') {
    return store.dispatch('user/refresh').then(() => {
      const config = error.config;
      return new Promise((resolve) => {
        resolve(axios(config))
      })
    });
  }
  if (error.response && error.response.data && error.response.data.error === 'Expired token') {
    return store.dispatch('user/logout');
  }
  throw error;
});

axios.interceptors.request.use(function (config) {
  if (store.state.user.token && !config.url.includes('auth/refresh')) {
    config.headers['Authorization'] = 'Bearer ' + store.state.user.token;
  }
  return config;
});

export default store;
