import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Vhtml from "@/components/Vhtml";
import {apiUrl} from "@/store/api"
import { Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import VueLazyLoad from 'vue3-lazyload';
import "vue-select/dist/vue-select.css";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import {mapGetters} from "vuex";

require('./assets/scss/app.scss');

const metaManager = createMetaManager();

const sal = require('sal.js');

store.dispatch('initialiseStore').then(() => {
  const app = createApp(App)
    .use(store)
    .use(router)
    .use(metaManager)
    .use(metaPlugin)
    .use(VueLazyLoad, { observerOptions	: { rootMargin : '200px'} });


  Sentry.init({
    app,
    environment: process.env.ENVIRONMENT,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_MSAL_REDIRECT_URI, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });


  app.mixin({
    data () {
      return {
        isOnline: false,
        isOffline: false
      }
    },
    components : {Swiper, SwiperSlide},
    mounted () {
      sal();

      if (typeof window !== 'undefined') {
        navigator.onLine ? this.isOnline = true : this.isOffline = true

        const onlineHandler = () => {
          this.$emit('online')
          this.isOnline = true
          this.isOffline = false
        }

        const offlineHandler = () => {
          this.$emit('offline')
          this.isOffline = true
          this.isOnline = false
        }

        window.addEventListener('online',  onlineHandler);
        window.addEventListener('offline',  offlineHandler);

      }
    },
    methods: {
      apiUrl(url) {
        return apiUrl(url);
      },

      triggerUserLink() {
        return this.authenticated
          ? this.$router.push({'name': 'settings'})
          : this.$store.dispatch('showLoginPopup');
      }
    },
    computed : {
      ...mapGetters({
        user: 'user/user',
        showLoginPopup: "showLoginPopup",
        showBoardPopup: "showBoardPopup",
        itemToSave: "itemToSave",
        successMessage: "successMessage",
        showRemovePopup: "showRemovePopup",
        filters : "preferences/filters",
      }),

      authenticated(){
        return Object.values(this.user).length !== 0;
      },

      appliedFilters() {
        return this.filters.themeFilter.length > 0 ||
          this.filters.tagFilter.length > 0 ||
          this.filters.groupFilter.length > 0 ||
          this.filters.selectedGroup !== null;
      }
    },
  });
  app.component('vhtml', Vhtml);

  app.mount('#app');
});
