<template>
  <div class="footer">
    <h2>Heb je een vraag of een suggestie?</h2>
    <p class="subtitle">Contacteer:</p>

    <div class="staff-profile">
      <div v-if="contact.img" class="image">
        <img :src="contact.img" alt="FOS Open Scounting"/>
      </div>

      <div class="staff-profile__data" :class="{'center' : !contact.img}">
        <p class="name">{{ contact.fullName ?? contact.title }}</p>
        <a v-show="contact.email" :href="`mailto:${contact.email}`" class="email">{{contact.email }}</a>
        <a class="tel" v-show="contact.tel" :href="`tel:${contact.tel}`">{{contact.tel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Footer",
  props : {
    contact : { type: Object, required : true}
  },
  computed: {
    ...mapGetters({

    })
  }
}
</script>
