<template>
  <Authenticated
      pageId="board"
      v-if="board"
      :back-button="{ route : 'map', title : board.title, force: true}"
      reset
  >
    <div class="container">

      <div class="content">
        <InfiniteScroll v-if="fetchedBoard.items.length > 0" :items="fetchedBoard.items">
          <template v-slot:item="{ item }">
            <button v-on:click="showRemoveBoardPopup(item)" class="reset remove-from-favorites">
              <SvgInline path="icons/exit.svg" />
            </button>
            <ArticleItem v-if="item.type === 'article' || item.type === 'poll' || item.type === 'event'" :data="this.articleBySlug(item.slug)" />
            <GameItem v-else :data="this.gameBySlug(item.slug)" />
          </template>
        </InfiniteScroll>

        <div v-else class="no-results">
          Deze map is momenteel nog leeg.
          <router-link class="btn btn-color-pink" :to="{name : 'popular'}">Voeg items toe</router-link>
        </div>
      </div>
    </div>
  </Authenticated>
</template>

<script>
import ArticleItem from "../organisms/ArticleItem";
import Authenticated from "../layouts/Authenticated";
import {mapGetters} from "vuex";
import GameItem from "@/organisms/GameItem";
import SvgInline from "@/components/SvgInline";
import InfiniteScroll from "@/molecules/InfiniteScroll";

export default {
  name: "SingleBoard",
  components: {InfiniteScroll, SvgInline, GameItem, Authenticated, ArticleItem},
  props: {
    board: {type: Object, required: true}
  },
  methods : {
    showRemoveBoardPopup(item) {
      const fullItem = (item.type === 'article' ? this.articleBySlug(item.slug) : this.gameBySlug(item.slug));

      this.$store.dispatch('setItemToRemove', { item : fullItem, board : this.board});
      this.$store.dispatch('showRemovePopup');
    }
  },
  computed : {
    ...mapGetters({
      articleBySlug: 'articles/bySlug',
      gameBySlug: 'games/bySlug',
      boardBySlug: 'preferences/boardBySlug'
    }),
    fetchedBoard() {
      return this.boardBySlug(this.board.slug);
    }
  },
  metaInfo(){
    if(!this.slug){
      return {}
    }
    return {
      title : this.board.title
    }
  },

}
</script>
