<template>
  <a :href="data.url" @click="viewItem" target="_blank" class="article-item">
      <div class="img-container">
        <img class="thumb"
             alt="fos open scouting scouts"
             :data-width="data.imageSize.width"
             :data-height="data.imageSize.height"
        />

        <div class="overlay-options">
          <Actions v-if="!hideActions" :subject="data" limited/>
        </div>
      </div>

      <div class="body">
        <h3>{{ data.title }}</h3>
        <div class="event-meta">
          <span class="date">{{ data.date }}</span>
          <span class="divider" v-if="data.date && data.location"> | </span>
          <span class="location">{{ data.location }}</span>
        </div>
        <span v-if="data.theme" class="topic">{{ data.theme.title }}</span>

        <ul>
          <li v-for="tag in data.tags" :key="`tag-${tag.id}`">#{{ tag.title }}</li>
        </ul>
      </div>

    </a>
</template>

<script>
import Actions from "@/molecules/Actions";

export default {
  name: "ArticleItem",
  components: {Actions},
  props : {
    data : { type : Object, required : true},
    hideActions: {type: Boolean, required: false, default: false},
  },
  methods: {
    viewItem() {
      this.$store.dispatch('viewItem', this.data);
    }
  }
}
</script>
