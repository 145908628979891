<template>
  <div class="step-1">
    <label for="game_name">Naam van het spel</label>
    <input type="text" id="game_name" name="game_name" v-model="game.name" v-on:input="checkName" v-on:keyup.enter="proceed">
    <span class="error" v-if="error">
      De naam kan alleen letters en cijfers bevatten
    </span>

    <button class="reset next-step-arrow"
            :class="{ 'active' : game.name.length > 2}"
            v-on:click="proceed"
    >
      <SvgInline path="icons/arrow-right.svg"/>
    </button>
  </div>
</template>

<script>
import SvgInline from "@/components/SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "Step1",
  components: {SvgInline},
  emits : ['update:modelValue', 'game-process'],
  data() {
    return {
      error : false
    }
  },
  computed: {
    ...mapGetters({
      game : 'newGame',
    })
  },
  methods : {
    checkName(){
      const character = /[^a-zA-Z0-9 ]/g;
      this.error = character.test(this.game.name);
    },
    proceed() {
      this.$store.dispatch('updateGame', this.game);
      if(this.game.name.length > 2)
        this.game.name = this.game.name.replace(/[^a-zA-Z0-9 ]/g, '');
        this.$emit("game-process");
    }
  },
}
</script>

<style scoped>

</style>
