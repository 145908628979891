<template>
  <Authenticated pageId="static" v-if="page" class-name="detail">
    <div class="container static-content detail-content">
      <div class="article-content mb-0">
        <h1>{{ page.title }}</h1>
      </div>
      <ToggleableContent :content="page.content" />
    </div>
  </Authenticated>
</template>

<script>
  import {mapGetters} from "vuex";
  import Authenticated from "@/layouts/Authenticated";
  import ToggleableContent from "@/molecules/ToggleableContent";

  export default {
    name: "StaticPage",
    components: {ToggleableContent, Authenticated},
    data() {
      return {
        handle: null,
        title : null,
      }
    },
    mounted() {
      this.handle = this.$route.meta.handle;
      this.title = this.page.title;
    },
    computed: {
      ...mapGetters({
        pageByHandle: 'pages/byHandle',
      }),
      page() {
        if (this.handle === null) {
          return null;
        }

        return this.pageByHandle(this.handle);
      },
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
  }
</script>
