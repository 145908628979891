<template>
  <div class="step-7">
    <div class="container">
      <p>{{ game.name }}</p>
      <span class="big-text">
        Foto/video
      </span>

      <input type="file"
             accept="image/png, image/gif, image/jpeg"
             class="reset add-media media d-none"
             ref="importButton"
             v-on:change="importMedia($event)"
             multiple/>

      <div class="media-list">
        <button class="reset add-media media" v-on:click="$refs.importButton.click()">
          <SvgInline path="icons/add.svg" />
        </button>

        <div
            v-for="image in media"
            :key="image"
            class="media"
            :style="{'backgroundImage' : `url(${createObjectURL(image)})`}">
          <button class="reset remove-button" v-on:click="removeImage(image)">
            <SvgInline path="icons/exit.svg" />
          </button>
        </div>
      </div>

    </div>

    <button class="reset next-step-arrow active" v-on:click="proceed">
      <SvgInline path="icons/arrow-right.svg" />
    </button>
  </div>

</template>

<script>
import SvgInline from "../../SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "Step6",
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  components : {
    SvgInline,

  },
  computed: {
    ...mapGetters({
      game : 'newGame',
    }),

    media() {
      return this.game.media.filter(image => image instanceof File);
    }
  },
  methods : {
    createObjectURL(file){
      return URL.createObjectURL(file);
    },
    importMedia(event) {
      this.game.media = [... new Set(
          [
            ... this.game.media,
            ...Object.values(event.target.files)
          ]
      )];
    },

    removeImage(image) {
      let media = [];
      this.game.media.forEach(item => {
        if(item !== image) media.push(item);
      })

      this.game.media = media;
    },
    proceed() {
      this.game.media = this.game.media.filter(image => image instanceof File);
      this.$store.dispatch('updateGame', this.game);
      this.$emit("game-process");
    },
  }
}
</script>