import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
    knownAuthorities: [process.env.VUE_APP_MSAL_KNOWN_AUTHORITY],
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

let msalFacebookConfig = { ... msalConfig };
msalFacebookConfig.auth.authority = "https://fosopenscoutingvzwb2c.b2clogin.com/fosopenscoutingvzwb2c.onmicrosoft.com/B2C_1_Scoutsmap_Facebook";
export const msalFbInstance = new PublicClientApplication(msalFacebookConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid profile email 15b9e958-2954-4e83-8b5d-4b8a3d0b5beb"],
};
