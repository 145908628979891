<template>
  <div class="step-6">
    <div class="container">
      <p>{{ game.name }}</p>
      <span class="big-text">
        Thema
      </span>
    </div>

    <swiper-wrapper>
      <swiper-slide v-for="theme in themes" :key="theme.id">
        <CategoryItem
            :active="game.theme === theme.id && game.theme !== null"
            :theme="theme"
            v-on:click="game.theme !== theme.id ? game.theme = theme.id : game.theme = null"
        />
      </swiper-slide>
    </swiper-wrapper>

    <div class="tags">
      <span class="big-text">Tags</span>
      <ul class="tag-options">
        <li v-for="tag in tags" :key="`tag_${tag.id}`">
          <TagItem
              :tag="tag"
              v-on:toggle="toggleTag(tag.id)"
              :active="game.tags.includes(tag.id)"
          />
        </li>
      </ul>
    </div>

    <div class="container">
      <button class="reset next-step-arrow active" v-on:click="proceed">
        <SvgInline path="icons/arrow-right.svg" />
      </button>
    </div>


  </div>

</template>

<script>
import {mapGetters} from "vuex";
import CategoryItem from "../../../molecules/CategoryItem";
import SvgInline from "../../SvgInline";
import TagItem from "../../../molecules/TagItem";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";

export default {
  name: "Step6",
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  components : {
    TagItem,
    SvgInline,
    CategoryItem,
    SwiperWrapper,
    SwiperSlide
  },
  data() {
    return {
      selectedTags : [],
    }
  },
  computed: {
    ...mapGetters({
      game : 'newGame',
      themes: 'themes/all',
      tags: 'tags/all'
    })
  },
  methods : {
    proceed() {
      this.$store.dispatch('updateGame', this.game);
      this.$emit("game-process");
    },
    toggleTag(tagId){
      const index = this.game.tags.indexOf(tagId);

      if (index === -1) {
        this.game.tags = [...this.game.tags, tagId];
      } else {
        this.game.tags.splice(index, 1);
      }
    }
  }
}
</script>
