<template>
  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <LoginPopup
        v-if="showLoginPopup"
        title="Aanmelden"
        text="Meld je aan om alle functionaliteiten te gebruiken" />
  </Transition>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <BoardPopup
        v-if="showBoardPopup"
        title="Toevoegen aan map"
        :item="itemToSave"
    />
  </Transition>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut"
  >
    <div v-if="successMessage" class="success-message">



      <div class="box animate_animated animate__fadeOut">
        <button class="reset exit-button" v-on:click="this.$store.dispatch('setSuccessMessage', null)">
          <SvgInline path="icons/exit.svg" />
        </button>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.576 408.576">
          <g data-name="Group 2937">
            <path data-name="Path 44290" d="M204.288,0C91.648,0,0,91.648,0,204.288S91.648,408.576,204.288,408.576s204.288-91.648,204.288-204.288S316.928,0,204.288,0ZM318.464,150.528,187.9,280.064a20.088,20.088,0,0,1-28.16.512L90.624,217.6a20.765,20.765,0,0,1-1.536-28.672A20.256,20.256,0,0,1,117.76,187.9l54.784,50.176L289.28,121.344a20.636,20.636,0,0,1,29.184,29.184Z" />
          </g>
        </svg>
        <p>{{ successMessage }}</p>
      </div>
    </div>
  </Transition>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <RemovePopup
        v-if="showRemovePopup"
        title="Verwijderen van je map"
        :item="itemToRemove"
        :text="'Ben je zeker om `' + itemToRemove.item.title + '` te verwijderen van je map?'"
    />
  </Transition>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <RemovePopup
        v-if="showRemoveBoardPopup"
        title="Verwijderen van map"
        :item="boardToRemove"
        type="board"
        :text="'Ben je zeker om `' + boardToRemove.title + '` te verwijderen?'"
    />
  </Transition>
</template>

<script>
import LoginPopup from "@/components/popups/LoginPopup";
import BoardPopup from "@/components/popups/BoardPopup";
import {mapGetters} from "vuex";
import RemovePopup from "@/components/popups/RemovePopup";
import SvgInline from "@/components/SvgInline";

export default {
  name: "AllPopups",
  components: {SvgInline, RemovePopup, BoardPopup, LoginPopup},
  computed : {
    ...mapGetters({
      showLoginPopup: "showLoginPopup",
      showBoardPopup: "showBoardPopup",
      showRemovePopup: "showRemovePopup",
      showFilter: "showFilter",
      showRemoveBoardPopup: "showRemoveBoardPopup",
      itemToSave: "itemToSave",
      itemToRemove : "itemToRemove",
      boardToRemove : "boardToRemove",
      successMessage: "successMessage",
      showSearchModal : "showSearchModal",
      showGameModal : "showGameModal"
    }),
    checkPopups(){
      const popups = [
        this.showLoginPopup,
        this.showBoardPopup,
        this.showRemovePopup,
        this.showRemoveBoardPopup,
        this.showSearchModal,
        this.showFilter,
        this.showGameModal
      ];

      return popups.includes(true);
    }
  },
  watch: {
    checkPopups(){
      if(this.checkPopups){
        document.querySelector('html').classList.add('overflow-hidden');
      } else {
        document.querySelector('html').classList.remove('overflow-hidden');
      }
    }
  }
}
</script>
