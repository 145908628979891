<template>
  <div class="interest-options icon-swiper"
       :ref="swiperType"
       :class="{'center' : center, 'group-options' : swiperType === 'groupSwiper'}"
  >
    <h2 v-if="title">{{ title }}</h2>
    <swiper
        :slides-per-view="slidesPerView"
        :space-between="0"
        v-bind="$props"
        :threshold="5"
    >
      <slot />
    </swiper>
  </div>
</template>

<script>
import { Swiper } from "swiper/vue/swiper-vue";

export default {
  name: "SwiperWrapper",
  components : { Swiper },
  data() {
    return {
      center : false,
      windowWidth : window.innerWidth
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkSwiperWidth);
    window.addEventListener("resize", this.setWindowWidth);

    this.setWindowWidth();
    this.checkSwiperWidth();
  },
  props : {
    swiperType : { type : String, required : false, default : 'themeSwiper'},
    title :  { type : String, required : false, default : null }
  },
  computed : {
    slidesPerView(){
      const windowWith = (this.windowWidth !== 0 ? this.windowWidth : window.innerWidth);

      const slides =  (windowWith / 175).toFixed(1).toString();
      let partInt = slides.substring(0, slides.indexOf('.'));

      return partInt + '.5';
    },
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    checkSwiperWidth() {
      if(this.$refs.themeSwiper){
        const slides = this.$refs.themeSwiper
            .getElementsByClassName('swiper-slide')

        let swiperWidth = 0;

        for(let slide of slides){
          swiperWidth = swiperWidth + slide.clientWidth;
        }

        this.center = ((swiperWidth + 100) < window.innerWidth);
      }

      else if(this.$refs.groupSwiper) {
        const slides = this.$refs.groupSwiper
            .getElementsByClassName('swiper-slide')
        let swiperWidth = 0;

        for(let slide of slides){
          swiperWidth = swiperWidth + slide.clientWidth;
        }

        this.center = ((swiperWidth + 100) < window.innerWidth);
      }
      return false;
    },
  }
}
</script>