import axios from "axios"
import {apiUrl, baseUrl} from "@/store/api";
import {emptyGame, Game} from "@/store/_classes";


export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    setData(state, games) {
      state.data = games.map(g => new Game(g));
    },
  },
  getters: {
    bySlug(state) {
      return (slug) => {
        const games = state.data.filter((val) => val.slug === slug || val.slugWithoutId === slug);
        if (games.length !== 1) {
          throw 'Game with slug "' + slug + '" not found or found multiple times';
        }

        return games.pop();
      }
    },
    byUser(state, getters, rootState) {
      const userId = parseInt(rootState.user.user.id);
      return state.data.filter((val) => parseInt(val.author_id) === userId);
    },
    related(state) {
      return (game, count = 3) => {
        let games = state.data.filter(g => g.type !== 'task')
          .filter(g => g.slug !== game.slug);

        //random sort
        for (let i = games.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [games[i], games[j]] = [games[j], games[i]];
        }

        return games.slice(0, count);
      }
    },
    filtered(state, getters, rootState) {
      return (type = 'default') => {
        const themeFilter = rootState.preferences.themeFilter;
        const tagFilter = rootState.preferences.tagFilter;
        const selectGroup = rootState.preferences.selectedGroup;
        const groupFilter = rootState.preferences.groupFilter;

        if(Array.isArray(selectGroup)){
          if(selectGroup.length !== 0 && !selectGroup.includes('games')) return [];
        }


        let val = state.data
          .filter(game => themeFilter.length === 0 || (game.theme && themeFilter.includes(game.theme.id)))
          .filter(game => game.type !== 'task')
          .filter(game => groupFilter.length === 0 || (game.ageGroups && game.ageGroups.some((group) => groupFilter.includes(group.id))))

        if (type === 'home') {
          const groupFilter = rootState.preferences.groupFilter;
          return val
            .filter(game => groupFilter.length === 0 || (game.ageGroup && groupFilter.includes(game.ageGroup.id)));
        }

        if(type === 'popular') {
          return val
            .filter(game => tagFilter.length === 0 || game.tags.some((tag) => tagFilter.includes(tag.id)))
            .filter(game => groupFilter.length === 0 || (game.ageGroup && groupFilter.includes(game.ageGroup.id)));
        }

        if(type === 'search'){
          val.filter(article => groupFilter.length === 0 || (article.ageGroup && groupFilter.includes(article.ageGroup.id)));
        }

        return val.filter(game => tagFilter.length === 0 || (game.tags && game.tags.some((tag) => tagFilter.includes(tag.id))));
      };
    },
    filteredByType(state, getters, rootState) {
      return (type) => {
        const themeFilter = rootState.preferences.themeFilter;
        const tagFilter = rootState.preferences.tagFilter;
        const groupFilter = rootState.preferences.groupFilter;

        if(type === 'task'){
          return state.data.filter(val => val.type && val.type === type)
        }

        let val = state.data
          .filter(game => themeFilter.length === 0 || (game.theme && themeFilter.includes(game.theme.id)))
          .filter(game => groupFilter.length === 0 || (game.ageGroups && game.ageGroups.some((group) => groupFilter.includes(group.id))))
          .filter(game => tagFilter.length === 0 || (game.tags && game.tags.some((tag) => tagFilter.includes(tag.id))));

        return val.filter(val => val.type && val.type === type)
      }
    },
    searched(state, getters, rootState) {
      return () => {
        const searchTerm = rootState.search.searchTerm;
        const themeSearch = rootState.search.themeSearch;
        const tagSearch = rootState.search.tagSearch;
        const selectGroup = rootState.search.selectedGroup;
        const groupSearch = rootState.search.groupSearch;

        if(Array.isArray(selectGroup)){
          if(selectGroup.length !== 0 && !selectGroup.includes('games')) return [];
        }

        let searchedData = state.data
          .filter(game => game.type !== 'task')
          .filter(game => tagSearch.length === 0 || game.tags.some((tag) => tagSearch.includes(tag.id)))
          .filter(game => groupSearch.length === 0 ||
            (game.ageGroups && game.ageGroups.some((group) => groupSearch.includes(group.id))));

        if(Object.values(themeSearch).length > 0){
          searchedData = searchedData.filter(game => game.theme && (themeSearch.length === 0 || themeSearch.includes(game.theme.id)))
        }

        if(searchTerm){
          searchedData = searchedData
            .filter(game =>
              game.title && game.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              game.summary && game.summary.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }

        return searchedData;

      }
    },
    forYou(state, getters, rootState) {
      let games = [];

      games = [...games, ...state.data.filter(game => rootState.user.user.themeInterests.length === 0 ||
        rootState.user.user.themeInterests.includes(game.theme?.id)
      )];

      games.filter(game => rootState.user.user.branch.length === 0 ||
        (game.ageGroups && game.ageGroups.some((group) => rootState.user.user.branch.includes(group.id)))
      );

      return [
        ...games.filter(val => val.featured === true),
        ...games.filter(val => val.featured === false),
      ];
    },
    all(state) {
      return state.data;
    }
  },
  actions: {
    fetch({ commit }) {
      return axios.get(apiUrl('games.json'))
        .then(response => {
          commit('setData', response.data.data);

          return response;
        })
    },
    async addView({ commit }, item) {
      await axios.post(baseUrl('add-view'), {id: item.id });
    },
    async createGame({commit, state, rootState}, game) {
      let formData = new FormData();

      const media = game.media.filter(item => item instanceof File);
      const downloads = game.content.downloads.filter(item => item instanceof File);

      media.forEach((item, i) => {
        formData.append(`media_${i}`, item);
      });

      downloads.forEach((item, i) => {
        formData.append(`download_${i}`, item);
      });

      formData.append('name', game.name);
      formData.append('summary', game.summary);
      formData.append('type', game.type);
      formData.append('tags', game.tags);
      formData.append('theme', game.theme);

      formData.append('playerCountMin', game.properties.players.min);
      formData.append('playerCountMax', game.properties.players.max);
      formData.append('location', game.properties.location.value);
      formData.append('ageMin', game.properties.age.min);
      formData.append('ageMax', game.properties.age.max);
      formData.append('gameTimeMin', game.properties.time.min.value);
      formData.append('gameTimeMax', game.properties.time.max.value);

      formData.append('material', game.content.material);
      formData.append('preparation', game.content.preparation);
      formData.append('story', game.content.story);
      formData.append('flow', game.content.flow);

      axios({
        method: "post",
        url: baseUrl('create-game'),
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          rootState.newGame = emptyGame;
          rootState.step = 0;

          rootState.successMessage = response.data.game + ' succesvol aangemaakt en wordt beoordeeld.';

          setTimeout(() => {
            rootState.successMessage = null;
          },4000);

          return true;
        }, (error) => {

          const messages = error.response.data.data;
          if(messages){
            return rootState.errorMessage = messages;
          }
          rootState.errorMessage = {media : [error.response.data.error]};
      })
    },
  },
};

