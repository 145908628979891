import { createRouter, createWebHistory } from 'vue-router';
import views from '../views';
import store from './../store/index';

const routes = [
  {
    path: '/',
    name: 'account',
    component: views.Intro
  },
  {
    path: '/registreren',
    name: 'register',
    component: views.Register,
    props : true,
  },
  {
    path: '/login',
    name: 'login',
    component: views.Login,
  },
  {
    path: '/wachtwoord-resetten',
    name: 'password.reset',
    component: views.ResetPassword,
  },
  {
    path: '/nieuw-wachtwoord',
    name: 'password.new',
    component: views.SetNewPassword,
  },
  {
    path: '/discover',
    name: 'home',
    component: views.Home,
    meta: { background: 'grey', requiresAuth: true },
    beforeEnter: (to) => {store.dispatch('setOrigin', to)}
  },
  {
    path: '/artikelen',
    name: 'articles',
    component: views.Articles,
    meta: { background: 'grey' },
    beforeEnter: (to) => { store.dispatch('setOrigin', to) }
  },
  {
    path: '/artikelen/:slug~:id',
    name: 'article',
    component: views.SingleArticle,
    meta: { background: 'grey' },
    beforeEnter: async (to, from, next) => {
      try {
        const article = store.getters['articles/bySlug'](to.params.slug);
        to.params.article = article;
      } catch (e) {
        await store.dispatch('setLoadingState', true);
        await store.dispatch('articles/fetch');
        try {
          const article = store.getters['articles/bySlug'](to.params.slug);
          to.params.article = article;
        } catch (e) {
          next({ name: 'notfound' });
        } finally {
          await store.dispatch('setLoadingState', false);
        }
      }

      next();
    },
    props: true
  },
  {
    path: '/artikelen/:slug',
    name: 'article.withoutId',
    component: views.SingleArticle,
    meta: { background: 'grey' },
    beforeEnter: async (to, from, next) => {
      try {
        const article = store.getters['articles/bySlug'](to.params.slug);

        next({ name : 'article', params : {slug : article.slug, id: article.id}});
      } catch (e) {
        next({ name: 'notfound' });
      }


    },
    props: true
  },
  {
    path: '/polls/:slug',
    name: 'poll',
    component: views.SinglePoll,
    meta: { background: 'green' },
    beforeEnter: async (to, from, next) => {
      try {
        const poll = store.getters['articles/bySlug'](to.params.slug);
        to.params.poll = poll;
      } catch (e) {
        next({ name: 'notfound' });
      }

      next();
    },
    props: true
  },
  {
    path: '/spelen',
    name: 'games',
    component: views.Games,
    meta: { background: 'grey' },
    beforeEnter: (to) => {
      store.dispatch('preferences/resetFilters');
      store.dispatch('setOrigin', to)
    }
  },
  {
    path: '/spelen/:slug~:id',
    name: 'game',
    component: views.SingleGame,
    meta: { background: 'grey' },
    beforeEnter: async (to, from, next) => {
      try {
        const game = store.getters['games/bySlug'](to.params.slug);
        to.params.game = game;
      } catch (e) {
        next({ name: 'notfound' });
      }

      next();
    },
    props : true
  },
  {
    path: '/spelen/:slug',
    name: 'game.withoutId',
    component: views.SingleGame,
    meta: { background: 'grey' },
    beforeEnter: async (to, from, next) => {
      try {
        const game = store.getters['games/bySlug'](to.params.slug);

        next({ name : 'game', params : {slug : game.slug, id: game.id}});
      } catch (e) {
        next({ name: 'notfound' });
      }
    },
    props: true
  },
  {
    path: '/spel-toevoegen',
    name: 'example.game',
    component: views.ExampleGame,
    meta: { background: 'grey', requiresAuth: true }
  },
  {
    path: '/populair',
    name: 'popular',
    component: views.Popular,
    meta: { background: 'grey' },
    beforeEnter: (to) => {
      store.dispatch('setOrigin', to)
    }
  },
  {
    path: '/map',
    name: 'map',
    component: views.Map,
    meta: { background: 'grey', requiresAuth: true },
    props : true,
  },
  {
    path: '/map/:slug',
    name: 'board',
    component: views.SingleBoard,
    meta: { background: 'grey', requiresAuth: true },
    props : true,
    beforeEnter: async (to, from, next) => {
      try {
        const board = store.getters['preferences/boardBySlug'](to.params.slug);
        to.params.board = board;
        await store.dispatch('setOrigin', to);
      } catch (e) {
        next({ name: 'notfound' });
      }

      next();
    },
  },
  {
    path: '/instellingen',
    name: 'settings',
    component: views.Settings
  },
  {
    path: '/instellingen/profiel',
    name: 'settings.profile',
    component: views.Profile,
    meta: { requiresAuth: true },
  },
  {
    path: '/zoekresultaten',
    name: 'search-results',
    component: views.SearchResults,
    meta: { background: 'grey' }
  },
  {
    path: '/instellingen/account',
    name: 'settings.account',
    component: views.AccountSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/instellingen/contact',
    name: 'settings.contact',
    component: views.StaticPage,
    meta: { handle: 'contact', background: 'grey' },
  },
  {
    path: '/instellingen/nuttige-bestanden',
    name: 'settings.important-files',
    component: views.StaticPage,
    meta: { handle: 'importantFiles', background: 'grey' },
  },
  {
    path: '/instellingen/over',
    name: 'settings.about',
    component: views.StaticPage,
    meta: { handle: 'aboutUs', background: 'grey' },
  },
  {
    path: '/vrijwilliger-worden',
    name: 'volunteer',
    component: views.StaticPage,
    meta: { handle: 'volunteer', background: 'grey' },
  },
  {
    path: '/pagina-niet-gevonden',
    name: 'notfound',
    component: views.NotFound,
    meta: { background: 'grey' },
  },
  {
    path: '/:catchAll(.*)',
    beforeEnter: (to, from, next) => { next('/pagina-niet-gevonden') }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (store.state.usePosition) {
    store.commit('removePosition');
    to.meta.usePosition = true;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.user.status.loggedIn) {
      if (typeof from.name === 'undefined') {
        next({name: 'account'});
      } else {
        store.dispatch('showLoginPopup');
        next(from);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

window.onunload = () => {
  store.dispatch('hidePopups');
};

export default router;
