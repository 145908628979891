import axios from "axios";
import {apiUrl, baseUrl} from "@/store/api";

export default {
  namespaced: true,
  state: {
    selectedGroup: null,
    themeFilter: [],
    tagFilter: [],
    groupFilter: [],
    boards: [],
  },
  mutations: {
    resetFilters(state){
      state.selectedGroup = null;
      state.themeFilter = [];
      state.groupFilter = [];
      state.tagFilter = [];
    },
    resetBoards(state){
      state.boards = [];
    },
    toggleThemeFilter(state, id) {
      const index = state.themeFilter.indexOf(id);

      if (index === -1) {
        state.themeFilter = [...state.themeFilter, id];
      } else {
        state.themeFilter.splice(index, 1);
      }
    },
    toggleGroupFilter(state, id) {
      if(state.groupFilter){
        const index = state.groupFilter.indexOf(id);

        if (index === -1) {
          state.groupFilter = [...state.groupFilter, id];
        } else {
          state.groupFilter.splice(index, 1);
        }
      } else {
        state.groupFilter.push(id);
      }

    },
    addBoard(state, board) {
      state.boards = [board, ...state.boards];
    },
    setBoardData(state, boards) {
      state.boards = boards;
    },
    setSelectedGroup(state, group) {
      state.selectedGroup = group;
    },
    toggleTagFilter(state, id) {
      const index = state.tagFilter.indexOf(id);

      if (index === -1) {
        state.tagFilter = [...state.tagFilter, id];
      } else {
        state.tagFilter.splice(index, 1);
      }
    },
    setThemeFilter(state, filter) {
      state.themeFilter = filter;
    },
    setGroupFilter(state, filter) {
      state.groupFilter = filter;
    },
    setTagFilter(state, filter) {
      state.tagFilter = filter;
    }
  },
  getters: {
    themeFilter(state) {
      return state.themeFilter;
    },
    groupFilter(state) {
      return state.groupFilter;
    },
    tagFilter(state) {
      return state.tagFilter;
    },
    boards(state) {
      return state.boards;
    },
    filters(state) {
      return {
        selectedGroup: state.selectedGroup,
        groupFilter: state.groupFilter,
        tagFilter: state.tagFilter,
        themeFilter: state.themeFilter
      }
    },
    boardBySlug(state) {
      return (slug) => {
        const boards = state.boards.filter((val) => val.slug === slug);
        if (boards.length !== 1) {
          throw 'Board with slug "' + slug + '" not found or found multiple times';
        }

        return boards.pop();
      }
    },
    boardById(state) {
      return (id) => {
        const boards = state.boards.filter((val) => val.id === id);
        if (boards.length !== 1) {
          throw 'Board with id "' + id + '" not found or found multiple times';
        }

        return boards.pop();
      }
    },
  },
  actions: {
    fetchBoards({commit, rootState}) {
      return axios.get(apiUrl(`boards.json`))
        .then(response => {
          commit('setBoardData', response.data.data)
        })
    },
    async createBoard({commit, state, rootState}, name) {
      await axios.post(baseUrl('create-board'), {name: name}).then((response) => {

        if(response.data.error){
          return rootState.errorMessage = response.data.message;
        }

        commit('addBoard', response.data.board);
        return response.data.board;
      });
    },
    async removeBoard({commit, state, rootState}, board) {
      await axios.post(baseUrl('remove-board'), {board: board}).then((response) => {
        commit('setBoardData', response.data.boards);
        rootState.successMessage = response.data.removed.board + " is succesvol verwijderd";
        rootState.showRemoveBoardPopup = false;
        rootState.showRemovePopup = false;

        setTimeout(() => {
          rootState.successMessage = null;
        },2000);

        return response.data.boards;
      });
    },
    async doFilter({ commit }, filter) {
      await commit('setGroupFilter', filter.groupFilter);
      await commit('setTagFilter', filter.tagFilter);
      await commit('setThemeFilter', filter.themeFilter);
      await commit('setSelectedGroup', filter.selectedGroup);
    },
    async toggleThemeFilter({commit, rootState, state}, theme) {
      await commit('toggleThemeFilter', theme.id);
    },
    async toggleGroupFilter({commit, rootState, state}, group) {
      await commit('toggleGroupFilter', group.id);
    },
    async toggleTagFilter({commit, rootState, state}, tag) {
      await commit('toggleTagFilter', tag.id);
    },
    async resetFilters({commit, rootState, state}) {
      await commit('resetFilters');
    },
    async resetBoards({commit, rootState, state}) {
      await commit('resetBoards');
    },
  },
};

