<template>
  <div class="actions">

    <ul>
      <li class="action" v-show="authenticated && !limited" :class="{ 'active': isLiked }">
        <button class="reset" v-on:click.prevent="likeOrUnlike">
          <SvgInline path="icons/like.svg" /><span>Vind ik leuk</span>
        </button>
      </li>
      <li class="action" v-show="authenticated && !limited" :class="{ 'active': isFavourited }">
        <router-link v-if="!user" :to="{ name : 'account'}">
          <SvgInline path="icons/save.svg" /><span>Bewaar</span>
        </router-link>
        <span @click="toggleSavePopup" v-else>
          <SvgInline path="icons/save.svg" /><span v-if="!isFavourited">Bewaar</span><span v-else>Bewaard</span>
        </span>
      </li>

      <li class="action" v-show="canShare">
        <button class="reset" v-on:click.stop.prevent="share">
          <SvgInline path="icons/share.svg" /><span>Deel</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>

import SvgInline from "@/components/SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "Actions",
  components : {
    SvgInline
  },
  data() {
    return {
      showSavePopup : false,
      route : window.location.href,
      shareData : null,
    }
  },
  props : {
    subject: { type: Object, required: true },
    limited : { type : Boolean,  required : false},
    poll : { type: Boolean, required : false }
  },
  computed: {
    canShare() {
      return typeof navigator.share !== 'undefined';
    },
    isFavourited() {
      return this.user.hasFavourited(this.subject);
    },
    isLiked() {
      return this.user.hasLiked(this.subject);
    },
    ...mapGetters({
      boards: 'preferences/boards',
    }),
  },
  mounted() {
    this.shareData = {
      title: this.subject.title,
      text: this.subject.summary,
      url: this.getUrl()
    };
  },
  methods: {
    async share() {
      try {
        await navigator.share(this.shareData)
      } catch(err) {
        console.log(err);
      }
    },
    togglePopup() {
      this.authenticated ? this.toggleSavePopup() : this.toggleLoginPopup()
    },
    toggleLoginPopup() {
      this.$store.dispatch('showLoginPopup');
    },
    toggleSavePopup() {
      this.$store.dispatch('setItemToSave', this.subject);
      this.$store.dispatch('showBoardPopup')
    },

    getUrl() {
      const type = this.subject.type === 'article' ? 'artikelen' : 'spelen';
      return window.location.origin + '/' + type + '/' + this.subject.slug + '~' + this.subject.id;
    },
    likeOrUnlike() {
      if (this.isLiked) {
        this.unlike();
      } else {
        this.like();
      }
    },
    like() {
      if(this.authenticated){
        if (this.isLiked) {
          return;
        }

        this.$store.dispatch('user/likeItem', this.subject);
      } else {
        this.$store.dispatch('showLoginPopup');
      }
    },
    unlike() {
      if(this.authenticated){
        if (!this.isLiked) {
          return;
        }

        this.$store.dispatch('user/unlikeItem', this.subject);
      } else {
        this.$store.dispatch('showLoginPopup');
      }
    },
  },
}
</script>

<style scoped>

</style>
