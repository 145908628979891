<template>
  <nav class="navigator discover-navigator">
      <router-link :active-class="'active'" :to="{ name : 'popular'}">Populair</router-link>
      <router-link :active-class="'active'" :to="{ name : 'articles'}">Lezen</router-link>
      <router-link :active-class="'active'" :to="{ name : 'games'}">Spelen</router-link>
      <button v-if="!authenticated" class="reset" v-on:click="this.$store.dispatch('showLoginPopup')">Voor jou</button>
      <router-link v-else :active-class="'active'" :to="{ name : 'home'}">Voor jou</router-link>
  </nav>
</template>

<script>
export default {
  name: "Navigator"
}
</script>

<style scoped>

</style>