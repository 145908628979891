<template>
  <div
      class="group-item"
      v-on:click="$emit('toggle')"
      :class="{'active' : active}"
  >
    <img :src="group.icon" :alt="`FOS Open scouting ${group.title}`">
    <span v-if="game">{{ group.ageRange }}</span>
    <span v-else>{{ group.title }}</span>
  </div>
</template>

<script>
export default {
  name: "AgeGroupItem",
  emits : ['toggle'],
  props : {
    group : { type : Object, required : true},
    game : { type : Boolean, required : false},
    active : { type : Boolean, required : false}
  },
}
</script>
