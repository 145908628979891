<template>
  <Authenticated pageId="home-page" withFilter>
    <div class="container">
      <h1 class="text-align-center">Ontdek</h1>
    </div>

    <div class="container">
      <div class="nav-content">
        <Navigator />
      </div>

      <div class="content" :class="{'hidden' : showFilter || showSearchModal }">
        <InfiniteScroll :items="items">
          <template v-slot:item="{ item }">
            <ArticleItem v-if="item.type === 'article' || item.type === 'poll' || item.type === 'event'" :data="item" />
            <GameItem v-else-if="item.type !== 'task'" :data="item" />
          </template>
        </InfiniteScroll>
      </div>
    </div>
  </Authenticated>
</template>

<script>
import ArticleItem from "@/organisms/ArticleItem";
import Navigator from "@/components/layout/Navigator";
import Authenticated from "@/layouts/Authenticated";
import { mapGetters } from 'vuex';
import GameItem from "@/organisms/GameItem";
import InfiniteScroll from "@/molecules/InfiniteScroll";

export default {
  name: 'Home',
  components: {InfiniteScroll, GameItem, Authenticated, Navigator, ArticleItem},
  data() {
    return {
      swiperWidth : false,
      desktopWidth : window.innerWidth,
      windowWidth : 0,
    }
  },
  mounted() {
    window.addEventListener('load', this.setDesktopWidth);
    window.addEventListener('resize', this.setDesktopWidth);
  },
  created() {
    window.addEventListener('resize', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      items: 'gamesAndArticlesForYou',
      showFilter : 'showFilter',
      showSearchModal : 'showSearchModal'
    }),

    slidesPerView(){
      const windowWith = (this.windowWidth !== 0 ? this.windowWidth : window.innerWidth);

      const slides =  (windowWith / 175).toFixed(1).toString();
      let partInt = slides.substring(0, slides.indexOf('.'));

      return partInt + '.5';
    }
  },
  metaInfo() {
    return {
      title: 'Ontdek',
    }
  },
  methods : {
    setDesktopWidth(e) {
      this.desktopWidth = e.currentTarget.innerWidth > 500;
    },
    handleScroll() {
      this.windowWidth = window.innerWidth;
    }
  }
};

</script>
