<template>
  <Authenticated :back-button="{ route : 'popular', title : 'Mijn Scoutsmap', force: true}" page-id="scouts-map" reset>
    <div class="container">
      <button class="reset add-board-button" @click="togglePopup">
        <SvgInline path="icons/add-game.svg"/>
      </button>

      <div class="content">
        <InfiniteScroll :items="boards">
          <template v-slot:item="{ item }">
            <div class="item">
              <button v-on:click.prevent="showRemoveBoardPopup(item)" class="reset remove-board">
                <SvgInline path="icons/exit.svg" />
              </button>
              <BoardItem :data="item" />
            </div>
          </template>
        </InfiniteScroll>
      </div>
    </div>

    <Popup v-show="showPopup" title="Nieuwe map"  v-on:exit="togglePopup">
      <form v-on:submit.prevent>
        <div class="input-group">
          <label for="board-title">Titel</label>
          <input id="board-title" type="text" v-model="boardTitle" />
        </div>
        <button
            class="btn create-board btn-color-green"
            :class="{'active' : boardTitle.length > 2}"
            v-on:click="submit"
        >
          Map aanmaken
        </button>
      </form>
    </Popup>

    <div class="errorMessage" v-if="errorMessage">
    <span>
      {{ errorMessage }}
      <button class="reset exit" v-on:click="this.$store.dispatch('setErrorMessage', null);">
        <SvgInline path="icons/exit.svg" />
      </button>
    </span>

    </div>

  </Authenticated>
</template>

<script>
import Authenticated from "../layouts/Authenticated";
import SvgInline from "../components/SvgInline";
import BoardItem from "../organisms/BoardItem";
import {mapGetters} from "vuex";
import Popup from "@/components/popups/Popup";
import InfiniteScroll from "@/molecules/InfiniteScroll";

export default {
  name: "Map",
  components: {InfiniteScroll, Popup, BoardItem, SvgInline, Authenticated},
  data() {
    return {
      searchTerm : '',
      boardTitle : '',
      showPopup : false,
      itemToAdd : null,
    }
  },
  mounted() {
    this.checkForItemToNewBoard();

    if(this.authenticated){
      this.$store.dispatch('preferences/fetchBoards');
    }
  },
  computed: {
    ...mapGetters({
      boards: 'preferences/boards',
      errorMessage : "errorMessage"
    }),
  },
  methods : {
    toggleFilter() {
      this.$store.dispatch('showFilter');
    },
    showRemoveBoardPopup(board) {
      this.$store.dispatch('setBoardToRemove', board);
      this.$store.dispatch('showRemoveBoardPopup');
    },

    togglePopup() {
      if(!this.authenticated){
        return this.$store.dispatch('showLoginPopup');
      }
      this.boardTitle = '';
      this.showPopup = !this.showPopup;
    },
    submit() {
      if (this.boardTitle === null) {
        return;
      }
      this.$store.dispatch('preferences/createBoard', this.boardTitle).then(() => {
        /*

        todo : add item to created board
        if(this.itemToAdd){
          this.$store.dispatch('user/saveItem', {item : this.data.id, board : })
        }*/
        this.togglePopup();
      });
    },
    checkForItemToNewBoard()
    {
      if(this.$route.params.item){
        this.itemToAdd = this.$route.params.item;
        this.showPopup = true;
      }
    }
  },
  metaInfo() {
    return {
      title: 'Mijn scoutsmap',
    }
  },
}
</script>

<style scoped>

</style>
