<template>
  <div v-swap="html"></div>
</template>

<script>
import DOMPurify from "dompurify";

export default {
  name: "Vhtml",
  props: {
    html: {
      type: String,
      required: true
    }
  },
  directives: {
    swap: {
      mounted(el, binding) {
        // createContextualFragment allows script execution
        // why you would probably want to sanitize the html
        // e.g. using https://github.com/cure53/DOMPurify
        let safe = DOMPurify.sanitize(binding.value)

        let frag = document.createRange()
            .createContextualFragment(safe)
        el.replaceWith(frag)
      }
    }
  },
}
</script>
