<template>
  <div class="body detail-content" v-html="content" ref="body" />
</template>

<script>
import axios from "axios";
import {baseUrl} from "@/store/api";

export default {
  name: "ToggleableContent",
  props: {
    content: {type: String, required: true}
  },
  mounted() {
    // override redactor styling
    this.makeContentToggleable();
    this.makePollInteractive();
    this.setBlockQuoteMarks();
    this.setIFrameDefaultSize();
  },
  methods : {
    makeContentToggleable() {
      const headers = this.$refs.body.getElementsByClassName('article-header');

      for(let header of headers) {
        const sectionNr = header.getAttribute('data-header');
        const open = header.getAttribute('data-open');
        const section =  this.$refs.body
            .querySelector(`.article-section[data-section="${sectionNr}"]`);

        header.addEventListener('click', () => {
          header.classList.toggle('active')
          if(section) {
            section.classList.toggle('active');
          }

        });

        if(open) {
          section.classList.add('active');
        }
      }
    },
    makePollInteractive() {
      const polls = this.$refs.body.getElementsByClassName('poll');
      for(let poll of polls) {
        const pollId = poll.getAttribute('data-poll');
        const answers = this.$refs.body.getElementsByClassName('answer');
        const submitButton = this.$refs.body.querySelector('button.submit');
        const thankYou = this.$refs.body.querySelector(`.poll-${pollId}`);

        for(let answer of answers){
          answer.addEventListener('click', () => {
            Object.values(answers).forEach(answer => {answer.classList.remove('active')});
            answer.classList.add('active');
          })
        }

        submitButton.addEventListener('click', () => {
          const selectedAnswer = this.$refs.body.getElementsByClassName('active')[0];
          const answer = selectedAnswer.querySelector('span').innerText;

          axios.post(baseUrl('answer-poll'), {
            question: pollId,
            answer: answer
          }).then(() => {
            poll.classList.add('hide');
            thankYou.classList.add('active')

          });
        })
      }

    },

    setBlockQuoteMarks(){
      const blockquotes = this.$refs.body.querySelectorAll(".article-content blockquote");
      blockquotes.forEach((blockquote) => {
        if(!blockquote.innerHTML.includes('“') && !blockquote.innerHTML.includes('“')) {
          blockquote.innerHTML = `“ ${blockquote.innerHTML} ”`
        }

      })
    },

    setIFrameDefaultSize() {
      const iframes = this.$refs.body.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        iframe.setAttribute('width', '100%');
      })
    },
  }
}
</script>
