import axios from "axios"
import {apiUrl, baseUrl} from "@/store/api";
import {User} from "@/store/_classes";
import {loginRequest, msalInstance, msalFbInstance} from "@/authConfig";

const html = document.querySelector('html');

export default {
  namespaced: true,
  state: { status: { loggedIn: false }, user: null, token: null, csrf: null, refreshToken: null, loginType: null },
  getters: {
    user : state => new User(state.user),
  },
  actions: {
    likeItem({ commit, state }, item) {
      commit('likeItem',item);
      return axios.post(baseUrl('like-item'), {item: item.id});
    },
    unlikeItem({ commit, state }, item) {
      commit('unlikeItem',item);
      return axios.post(baseUrl('unlike-item'), {item: item.id});
    },
    csrfToken({ commit }) {
      axios.get(baseUrl('auth/session-info'))
        .then(response => {
          commit('csrfTokenFetched', response.data.csrfTokenValue);
        });
    },
    async azureLogin({dispatch}) {
      const result = await msalInstance.loginPopup(loginRequest);
      return await dispatch('backendAzureLogin', result);
    },
    async facebookLogin({dispatch}) {
      const result = await msalFbInstance.loginPopup(loginRequest);
      return await dispatch('backendAzureLogin', result);
    },
    async backendAzureLogin({commit}, result) {
      return axios.post(baseUrl('auth/azure-login'), result)
        .then(response => {
          response.data.loginType = 'azure';
          if (response.data.user) {
            commit('loginSuccess', response.data);
            return Promise.resolve(response.data);
          } else {
            commit('loginFailure');
            return Promise.reject(response.data);
          }
        }, error => {
          commit('loginFailure');
          return Promise.reject(error);
        });
    },
    login({ commit, state }, user) {
      const data = {
        credentials: {
          username: user.username,
          password: user.password
        },
        csrf_token: state.csrf
      };

      return axios.post(baseUrl('auth/login'), data)
        .then(response => {
          if (response.data.user) {
            commit('loginSuccess', response.data);
            return Promise.resolve(response.data);
          } else {
            commit('loginFailure');
            return Promise.reject(response.data);
          }
        }, error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    async refresh({ commit, state }) {
      if (!state.refreshToken) {
        return Promise.reject('No refresh token found');
      }

      if (!state.csrf) {
        await this.dispatch('user/csrfToken');
      }

      return await axios.get(baseUrl('auth/refresh'), {
        headers: {
          'Authorization': 'Bearer ' + state.refreshToken,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-TOKEN': state.csrf,
        }
      }).then(response => {
        commit('updateToken', response.data.token);
      });
    },
    async logout({ commit, rootState }) {
      rootState.preferences.boards = [];
      commit('logout');
    },
    register({ commit }, user) {
        return axios.post(baseUrl('auth/register'), user)
            .then(response => {
              response.data.loginType = 'default';
              if (response.data.user) {
                commit('registerSuccess', response.data);
                return Promise.resolve(response.data);
              } else {
                commit('registerFailure');
                return Promise.reject(response.data);
              }
            }, (error) => {
                return error.response.data;
            });
    },
    updateProfile({ commit, rootState}, user) {
      return axios.post(baseUrl('auth/update-profile'), user)
        .then(response => {
          if (response.data.user) {
            commit('registerSuccess', response.data);
            rootState.successMessage = "Profiel succesvol geüpdatet.";

            setTimeout(() => {
              rootState.successMessage = null;
            },2000);

            return Promise.resolve(response.data);
          } else {
            return Promise.reject(response.data);
          }
        }, (error) => {
          rootState.errorMessage = error.response.data.error;

          setTimeout(() => {
            rootState.errorMessage = null;
          },2000);

          return error.response.data;
        });
    },
    saveItem({ commit, rootState }, data) {
      return axios.post(baseUrl('add-to-board'), data).then(
        response => {

          rootState.preferences.boards = response.data.boards;
          rootState.successMessage = response.data.saved.title + " is succesvol toegevoegd aan " + response.data.saved.board;
          rootState.showBoardPopup = false;

          setTimeout(() => {
            rootState.successMessage = null;
          },2000);

        }
      );
    },
    removeItem({ commit, rootState }, data) {
      return axios.post(baseUrl('remove-from-board'), data).then(
        response => {

          rootState.preferences.boards = response.data.boards;
          rootState.successMessage = response.data.saved.title + " is succesvol verwijderd van je map";
          rootState.showRemoveBoardPopup = false;
          rootState.showRemovePopup = false;



          setTimeout(() => {
            rootState.successMessage = null;
          },2000);

        }
      );
    },
  },
  mutations: {
    csrfTokenFetched(state, data) {
      state.csrf = data;
    },
    updateBoards(state, data) {
      if(data) {
        state.preferences.boards = data;
        this.commit('user/update', state.user);
      }
    },
    update(state, data) {
      state.user = data;
    },
    likeItem(state, item) {
      state.user.likedItems.push(item.id);
    },
    unlikeItem(state, item) {
      const index = state.user.likedItems.indexOf(item.id);
      if (index !== -1) {
        state.user.likedItems.splice(index, 1);
      }
    },
    updateToken(state, data) {
      state.token = data;
    },
    updateType(state, data) {
      state.loginType = data;
    },
    updateRefreshToken(state, data) {
      state.refreshToken = data;
    },
    registerSuccess(state, data) {
      state.status.loggedIn = true;
      this.commit('user/loginSuccess', data);
      this.commit('user/update', data.user);
    },
    loginSuccess(state, data) {
      state.status.loggedIn = true;

      this.commit('user/update', data.user);
      this.commit('user/updateToken', data.token);
      this.commit('user/updateRefreshToken', data.refreshToken);
      this.commit('user/updateType', data.loginType ?? 'default');
      this.commit('user/updateBoards');
    },
    async logout(state, date) {
      state.status.loggedIn = false;
      this.commit('user/update', null);
      this.commit('user/updateToken', null);
      this.commit('user/updateRefreshToken', null);
      if (state.loginType === 'azure') {
        await msalInstance.logoutPopup(loginRequest);
      }
      this.commit('user/updateType', null);
    },
    registerFailure(state) {
      state.status.loggedIn = false;
      console.log("Registration failed");
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      this.commit('user/update', null);
      this.commit('user/updateToken', null);
      this.commit('user/updateRefreshToken', null);
      this.commit('user/updateType', null);
      this.dispatch('user/csrfToken');
    },
  }
};
