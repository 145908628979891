<template>
  <button
      v-on:click="viewExample"
      class="btn btn-color-light-red color-white example">
    Bekijk voorbeeld
  </button>

  <div class="step-8">
    <h2>Voeg een spel toe</h2>
    <div class="container pb-0">
      <div class="slider">
        <swiper :slides-per-view="1" class="header-img">
          <swiper-slide v-for="media in media" :key="`media_${media.id}`">
            <img :src="createObjectURL(media)" :alt="`FOS Open Scouting : ${game.title}`">
          </swiper-slide>
        </swiper>

        <div v-if="media.length === 0" class="default-img"></div>

        <div class="overlay-options">
          <button class="edit reset" v-on:click="this.$store.dispatch('setStep', 7)">
            <SvgInline path="icons/edit.svg" />
          </button>
        </div>
      </div>

      <h1>{{ game.name }}
        <button class="edit reset" v-on:click="this.$store.dispatch('setStep', 1)">
          <SvgInline path="icons/edit.svg" />
        </button>
      </h1>

      </div>

    <div class="meta">
      <div class="container">
        <div v-if="theme" class="theme">
          {{ theme.title }}
          <button class="edit reset" v-on:click="this.$store.dispatch('setStep', 6)">
            <SvgInline path="icons/edit.svg" />
          </button>
        </div>
        <div class="tags">
          <ul>
            <li
                v-for="tag in tags"
                :key="tag.id">
              <span>#{{ tag.title }}</span>
            </li>
          </ul>
          <button class="edit reset" v-on:click="this.$store.dispatch('setStep', 6)">
            <SvgInline path="icons/edit.svg" />
          </button>
        </div>

      </div>
    </div>

    <div class="body">
      <div class="container">

        <div class="input-group text">
          <label for="summary">Samenvatting</label>
          <textarea id="summary" v-model="game.summary" v-on:input="setRows" :rows="setRows" />
        </div>

        <div class="input-group text">
          <label for="material">Materiaal</label>
          <textarea id="material" v-model="game.content.material" v-on:input="setRows" :rows="setRows" />
        </div>

        <div class="input-group downloads">
          <label>Downloads</label>
          <div class="download-item" v-for="(download, index) in this.game.content.downloads" :key="`download_${index}`">
            {{ download.name }}

            <button class="reset remove-button" v-on:click="removeDownload(download)">
              <SvgInline path="icons/exit.svg" />
            </button>
          </div>
          <button class="reset add-download" v-on:click="$refs.importButton.click()">
            <SvgInline path="icons/add.svg" />
          </button>
          <input class="d-none" type="file" ref="importButton" v-on:change="importDownloads" multiple />
        </div>

        <div class="input-group text">
          <label for="preparation">Voorbereiding</label>
          <textarea id="preparation" v-model="game.content.preparation" v-on:input="setRows" :rows="setRows" />
        </div>

        <div class="input-group text">
          <label for="story">Verhaallijn</label>
          <textarea id="story" v-model="game.content.story" v-on:input="setRows" :rows="setRows" />
        </div>

        <div class="input-group text">
          <label for="flow">Spelverloop</label>
          <textarea id="flow" v-model="game.content.flow" v-on:input="setRows" :rows="setRows" />
        </div>

        <div class="input-group range-group text">
          <label id="duration">Spelduur</label>

          <div class="range" ref="range">
            <MultiRangeSlider
                :min="1"
                :max="190"
                :ruler="false"
                :label="false"
                :minValue="min"
                :maxValue="max"
                v-on:input="setTimeValues"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import SvgInline from "../../SvgInline";
import MultiRangeSlider from "multi-range-slider-vue";
import {mapGetters} from "vuex";

export default {
  name: "Step8",
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  components : {
    SvgInline,
    MultiRangeSlider
  },
  data() {
    return {
      downloads : [],
      min : 5,
      max : 120,
      range : [
        {value: 1, label: '1 min'},
        {value: 5, label: '5 min'},
        {value: 10, label: '10 min'},
        {value: 30, label: '30 min'},
        {value: 60, label: '1 u'},
        {value: 90, label: '1,5 u'},
        {value: 120, label: '2 u'},
        {value: 180, label: '3 u'}
      ],
    }
  },
  computed: {
    ...mapGetters({
      game : 'newGame',
      themeList: 'themes/all',
      tagList: 'tags/all'
    }),

    theme() {
      return this.themeList.filter(theme => theme.id === this.game.theme)[0];
    },

    tags() {
      return this.tagList.filter(tag => this.game.tags.includes(tag.id))
    },

    media() {
      return this.game.media.filter(image => image instanceof File);
    }
  },
  mounted() {
    this.min = this.game.properties.time.min.value ?? 30;
    this.max = this.game.properties.time.max.value ?? 120;
  },
  methods : {
    importDownloads(event) {
      this.game.content.downloads = [... new Set(
          [
              ... this.game.content.downloads,
              ...Object.values(event.target.files)
          ]
      )];
    },
    removeDownload(file) {
      let downloads = [];
      this.game.content.downloads.forEach(item => {
        if(item !== file) downloads.push(item);
      })

      this.game.content.downloads = downloads;
    },
    setRows(event){
      event.target.rows = event.target.scrollHeight / 34;
    },
    createObjectURL(file){
      return URL.createObjectURL(file);
    },

    viewExample() {
      document.querySelector('html').classList.remove('overflow-hidden');

      this.$store.dispatch('showGameModal', false);
      this.$store.dispatch('updateGame', this.game);
      this.$router.push({ name : 'example.game'});
    },

    setTimeValues(e) {
      let min = e.minValue;
      let max = e.maxValue;

      let minimum = null;
      let maximum = null;

      const maxCaption = this.$refs.range.querySelector('span.max-caption');

      if((max - min) < 40){
        maxCaption.classList.add('lower');
      } else {
        maxCaption.classList.remove('lower');
      }

      this.range.forEach(slot => {
        if(e.minValue >= slot.value) {
          minimum = slot
        }

        if(e.maxValue >= slot.value) {
          maximum = slot

          if(e.maxValue > 180) {
            maximum = {value: 'max', label: '> 3 u'};
          }
        }
      })

      this.game.properties.time.min = minimum;
      this.game.properties.time.max = maximum;
    },
  }
}
</script>
