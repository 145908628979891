<template>
  <a :href="`/polls/${data.slug}`" class="article-item poll-item">
    <div class="img-container">
      <span class="question">{{ data.question }}</span>
      <div class="overlay-options">
        <ul>
          <li></li>
        </ul>

        <Actions poll v-if="!hideActions" :subject="data" limited/>
      </div>
    </div>
  </a>
  <div class="body">
    <h3>Poll</h3>
    <span class="topic">{{ data.theme.title }}</span>

    <ul>
      <li v-for="tag in data.tags" :key="`tag-${tag.id}`">#{{ tag.title }}</li>
    </ul>

    <button class="save reset" v-if="user & !hideActions">
      <!--<SvgInline path="icons/save.svg" />-->
      <svg xmlns="http://www.w3.org/2000/svg" width="13.995" height="17.999" viewBox="0 0 13.995 17.999">
        <path data-name="Path 1147" d="M11.98,14.744q-.005.189-.012.377a1.079,1.079,0,0,1-.3.754.456.456,0,0,1-.318.125c-.49,0-.905-.585-1.233-.869-.56-.484-1.185-.91-1.782-1.35-.224-.165-2.456-1.951-2.52-1.906L5.639,12c-.9.637-1.82,1.25-2.7,1.909-.441.329-.874.669-1.289,1.029-.268.232-.675.791-1.067.79a.654.654,0,0,1-.567-.639c-.031-.921,0-1.848,0-2.77V8.626q0-3.692,0-7.384A2.826,2.826,0,0,1,.074.493.4.4,0,0,1,.141.356,1.5,1.5,0,0,1,1.4,0q.856.032,1.718.038c1.865.013,3.73.017,5.6.03L10.32.086c.358,0,1.061-.143,1.261.242a.6.6,0,0,1,.064.188,1.273,1.273,0,0,1,.016.139c.2,2.423.225,4.854.252,7.283.025,2.268.128,4.538.065,6.806" transform="translate(0.999 0.998)" stroke-width="2"/>
      </svg>
    </button>
  </div>
</template>

<script>
import Actions from "@/molecules/Actions";

export default {
  name: "PollItem",
  components : { Actions },
  props: {
    data: {type: Object, required: true},
    hideActions: {type: Boolean, required: false, default: false},
  }
}
</script>
