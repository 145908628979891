import axios from "axios"
import {apiUrl} from "@/store/api";


export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    setData(state, themes) {
      state.data = themes
    }
  },
  getters: {
    bySlug(state) {
      return (slug) => {
        const groups = state.data.filter((val) => val.slug === slug);
        if (groups.length !== 1) {
          throw 'Group with slug "' + slug + '" not found or found multiple times';
        }

        return groups.pop();
      }
    },
    all(state) {
      return state.data;
    }
  },
  actions: {
    fetch({ commit }) {
      return axios.get(apiUrl('groups.json'))
        .then(response => {
          commit('setData', response.data.data);

          return response;
        })
    }
  },
};

