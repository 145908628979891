step<template>
  <div class="step-4">

    <div class="img-frame-step4" :class="`frame-${step}`">
      <SvgInline path="branch/beaver.svg"/>
      <SvgInline path="branch/whelp.svg"/>
      <SvgInline path="branch/wolf.svg"/>
    </div>

    <p>{{ game.name }}</p>
    <span class="big-text">
      Aantal spelers
    </span>

    <div class="range">
      <MultiRangeSlider
          :min="1"
          :max="100"
          :ruler="false"
          :label="false"
          :minValue="this.game.properties.players.min"
          :maxValue="this.game.properties.players.max"
          @input="save"
      />
    </div>

    <button
        class="reset next-step-arrow active"
        v-on:click="proceed"
    >
      <SvgInline path="icons/arrow-right.svg"/>
    </button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MultiRangeSlider from "multi-range-slider-vue";
import SvgInline from "../../SvgInline";

export default {
  name: "Step4",
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  components : {
    SvgInline,
    MultiRangeSlider
  },
  computed: {
    ...mapGetters({
      game : 'newGame',
      groups : 'groups/all'
    })
  },
  methods : {
    save(e) {
      this.game.properties.players.min = e.minValue;
      this.game.properties.players.max = e.maxValue;

      this.$store.dispatch('updateGame', this.game);
    },
    proceed() {
      this.$emit("game-process");
    }
  }
}
</script>
