<template>
  <article class="board"
           data-sal="fade"
           data-sal-delay="100"
           data-sal-easing="ease-in"
           data-sal-duration="400"
  >
    <div v-on:click="!popup ? goTo(data.slug) : null">
      <div class="img-container" :class="{'empty' : !data.image}">
        <img v-if="data.image" class="thumb" :src="data.image" alt="FOS Scouting">
        <SvgInline v-else path="owl-wing-open.svg" />
      </div>
      <div class="body">
        <h3>{{ data.title}}</h3>
        <span class="saved-count">
          <SvgInline path="icons/save.svg" />
          {{ data.items.length }}
        </span>
      </div>
    </div>
  </article>
</template>

<script>
import SvgInline from "../components/SvgInline";

export default {
  name: "BoardItem",
  components: {SvgInline},
  props : {
    data : { type : Object , required : true },
    popup : { type: Boolean, required : false, default: false}
  },
  methods : {
    goTo(slug){
      this.$router.push({ name: 'board', params : {slug : slug}});
    }
  }
}
</script>
