<template>
  <div class="step-3">

    <p>{{ game.name }}</p>
    <span class="big-text">
      Voor welke leeftijden?
    </span>

    <div class="range">
      <MultiRangeSlider
          :min="5"
          :max="26"
          :ruler="false"
          :label="false"
          :minValue="game.properties.age.min"
          :maxValue="game.properties.age.max"
          @input="save"
      />
    </div>


    <button
        class="reset next-step-arrow active"
        v-on:click="proceed()"
    >
      <SvgInline path="icons/arrow-right.svg"/>
    </button>


  </div>
</template>

<script>
import MultiRangeSlider from "multi-range-slider-vue";
import SvgInline from "../../SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "Step3",
  components: {SvgInline, MultiRangeSlider},
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  computed: {
    ...mapGetters({
      game : 'newGame',
    })
  },
  methods : {
    save(e) {
      this.game.properties.age.min = e.minValue;
      this.game.properties.age.max = e.maxValue;

      this.$store.dispatch('updateGame', this.game);
    },
    proceed() {
      this.$emit("game-process");
    }
  }

}
</script>

<style scoped>

</style>
