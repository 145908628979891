<template>
  <div class="step-5">

    <p class="color-white">{{ game.name }}</p>
    <span class="big-text">
      Locatie
    </span>

    <div v-if="!showOutsideOptions" class="locations">
      <div class="location-wrap inside">
        <button class="reset location inside" v-on:click="proceed('inside', 'binnen')">
          <SvgInline path="icons/shape.svg"/>
          <img src="@/assets/images/icons/location-inside.svg" alt="location"/>
          <span>Binnen</span>
        </button>
      </div>

      <div class="location-wrap outside">
        <button class="reset location outside"  v-on:click="showOutsideOptions = true">
          <SvgInline path="icons/shape.svg"/>
          <img src="@/assets/images/icons/location-outside.svg" alt="location"/>
          <span>Buiten</span>
        </button>
      </div>
    </div>

    <div v-else class="locations outside-options">
      <div class="location-wrap field">
        <button class="reset location field"  v-on:click="proceed('field', 'veld')">
          <SvgInline path="icons/shape.svg"/>
          <img src="@/assets/images/icons/location-field.svg" alt="location"/>
        </button>
      </div>


      <div class="location-wrap forest">
        <button class="reset location forest"  v-on:click="proceed('forest', 'bos')">
          <SvgInline path="icons/shape.svg"/>
          <img src="@/assets/images/icons/location-forest.svg" alt="location"/>
        </button>
      </div>

      <div class="location-wrap city">
        <button class="reset location city"  v-on:click="proceed('city', 'stad')">
          <SvgInline path="icons/shape.svg"/>
          <img src="@/assets/images/icons/location-city.svg" alt="location"/>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import SvgInline from "../../SvgInline";

export default {
  name: "Step5",
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  components : {
    SvgInline

  },
  data() {
    return {
      showOutsideOptions : false,
    }
  },
  computed: {
    ...mapGetters({
      game : 'newGame',
    })
  },
  methods : {
    proceed(location, label) {
      this.game.properties.location = {value: location, label : label};
      this.$store.dispatch('updateGame', this.game);
      this.$emit("game-process");
    },
  }
}
</script>
