<template>
  <ul class="properties">
    <li class="group">
      <!--img :src="groupImage" :alt="`FOS open scouting : `" /-->
      <span v-show="detail">{{ `${age.min}j - ${age.max}j` }}</span>
    </li>
    <li class="player-count">
      <SvgInline v-if="players.min > 25 || players.max > 50" path="icons/player-count-many.svg" />
      <SvgInline v-else path="icons/player-count-medium.svg" />
      <span v-show="detail">{{ `${players.min} - ${players.max}` }}</span>
    </li>
    <li class="location">
      <SvgInline :path="`icons/location-${location.value}.svg`" />
      <span v-show="detail">{{ location.label }}</span>
    </li>
    <li v-show="detail" class="time"><SvgInline path="icons/time.svg" />{{ time }}</li>
  </ul>
</template>

<script>
import SvgInline from "../components/SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "GameProperties",
  components: {SvgInline},
  props : {
    properties : { type : Object, required : true},
    detail : { type : Boolean, required: false }
  },
  data() {
    return {
      players : {},
      time : null,
      location: null,
      age : {}
    }
  },
  created() {
    this.players = {
      min : this.$props.properties.players.min,
      max: this.$props.properties.players.max
    };

    this.age = {
      min : this.$props.properties.age.min,
      max: this.$props.properties.age.max
    };

    this.time = this.$props.properties.time.max.label;

    this.location = this.$props.properties.location;
  },
  computed : {
    ...mapGetters({
      groupBySlug : 'groups/bySlug'
    }),
    groupImage() {
      return this.groupBySlug(this.$props.properties.group);
    },
  },
  methods : {
    getMinimumAgeGroup(){

    }
  }
}
</script>

<style scoped>

</style>
