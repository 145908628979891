<template>
  <Authenticated pageId="game" class-name="example-game detail" v-if="authenticated && game" hide-bottom-navigation>
    <div class="action-buttons">
      <button
          v-on:click="goToEditPage"
          class="btn btn-color-pink color-white">
        Aanpassen
      </button>

      <button
          v-on:click="submitGame"
          class="btn btn-color-green color-white" :class="{'inactive' : checkErrors}">
        Publiceren
      </button>
    </div>


    <div class="container pb-0">
      <div class="slider">
        <swiper :slides-per-view="1" class="header-img">
          <swiper-slide v-for="media in media" :key="`media_${media.id}`">
            <div class="img-container">
              <img class="banner" :src="createObjectURL(media)" :alt="`FOS Open Scouting : ${game.title}`">
            </div>
          </swiper-slide>
        </swiper>

        <div v-if="media.length === 0" class="default-img">

          <button v-on:click="goToStep(7)" class="btn btn-color-blue">Voeg een afbeelding toe</button>
        </div>
      </div>

      <div class="meta">
        <h1>{{ game.name }}</h1>
        <p class="error" v-if="!game.name">Naam van spel is verplicht*</p>

        <GameProperties v-if="game.properties.location" :properties="game.properties" detail/>
        <p class="error" v-else>Locatie van spel is verplicht</p>

        <b v-if="theme" class="theme">{{ theme.title }}</b>
        <ul class="tags">
          <li
              v-for="tag in tags"
              :key="tag.id">
            <span>#{{ tag.title }}</span>
          </li>
        </ul>

        <p class="author">
          <!-- todo : author -->
          Auteur:
          <router-link to="#">{{ this.user.firstName + ' ' + this.user.lastName }}</router-link>
        </p>
      </div>
    </div>

    <div class="detail-content">
      <div class="container with-border">
        <nav class="navigator game-tab">
          <button v-on:click="tab = 'info'" :class="{ 'active' : tab === 'info'}" class="reset">Het spel</button>
          <button v-on:click="tab = 'material'" :class="{ 'active' : tab === 'material'}" class="reset">Materiaal
          </button>
          <button v-on:click="tab = 'preparation'" :class="{ 'active' : tab === 'preparation'}" class="reset">
            Voorbereiding
          </button>
        </nav>
      </div>

      <template v-if="tab === 'info'">
        <div v-if="game.summary" class="summary no-border">
          <div class="wrap"><p>{{ game.summary }}</p></div>
        </div>

        <div class="container article-content">
          <div v-if=game.content.story class="story" v-html="game.content.story"></div>
          <div v-if="game.content.flow" class="flow" v-html="game.content.flow"></div>
        </div>
      </template>

      <div v-if="tab === 'material'" class="material">
        <div class="wrap" v-html="game.content.material"></div>
      </div>
      <div v-if="tab === 'preparation'" class="preparation">
        <div class="wrap" v-html="game.content.preparation"></div>
      </div>

    </div>
  </Authenticated>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <LoadPopup v-if="showLoader" />
  </Transition>

  <div class="errorMessage" v-if="errorMessage">
    <span>
      {{ Object.values(errorMessage)[0][0] }}
      <button class="reset exit" v-on:click="this.$store.dispatch('setErrorMessage', null);">
        <SvgInline path="icons/exit.svg" />
      </button>
    </span>

  </div>
</template>

<script>
import Authenticated from "../layouts/Authenticated";
import {mapGetters} from "vuex";
import GameProperties from "../molecules/GameProperties";
import LoadPopup from "@/components/popups/LoadPopup";
import SvgInline from "@/components/SvgInline";

export default {
  name: "ExampleGame",
  components: {SvgInline, LoadPopup, GameProperties, Authenticated},
  data() {
    return {
      tab: 'info',
      showLoader : false
    }
  },
  computed: {
    ...mapGetters({
      gameBySlug: 'games/bySlug',
      game: 'newGame',
      themeList: 'themes/all',
      tagList: 'tags/all',
      errorMessage : 'errorMessage',
    }),

    theme() {
      return this.themeList.filter(theme => theme.id === this.game.theme)[0];
    },

    tags() {
      return this.tagList.filter(tag => this.game.tags.includes(tag.id))
    },

    media() {
      return this.game.media.filter(image => image instanceof File);
    },
    checkErrors()
    {
      return !this.game.name || this.game.properties.location.value === null;
    },
  },
  metaInfo() {
    return {
      title: 'nieuw spel aanmaken'
    }
  },
  methods: {
    goToEditPage() {
      this.$store.dispatch('showGameModal', true);
      this.$router.push({name : 'games', params : {showpopup : true }})
    },
    goToStep(step) {
      this.$store.dispatch('setStep', step);
      this.$store.dispatch('showGameModal');
      this.$router.push({name : 'games', params : {showpopup : true}});
    },
    createObjectURL(file) {
      return URL.createObjectURL(file);
    },
    async submitGame() {
      this.$store.dispatch('setErrorMessage', null);
      if(this.checkErrors){
        return;
      }

      this.showLoader = true;

      await this.$store.dispatch('games/createGame', this.game)
          .then(() => {
            this.showLoader = false;
            this.$store.dispatch('setStep', 1);
            this.$router.push({name: 'popular'});
          });
    }
  }
}
</script>
