<template>
  <Guest auth page-id="splash">
    <img class="banner img-auth" src="../assets/images/fos-open-scouting.svg" alt="img">
    <Loader style="height: 8rem;" />
    <img class="banner img-logo" src="../assets/images/fos-open-scouting-logo.svg" alt="FOS Open Scouting"/>
  </Guest>
</template>

<script>
import Guest from "@/layouts/Guest";
import Loader from "@/components/layout/Loader";
export default {
  name: "SplashScreen",
  components: {Loader, Guest},
  metaInfo() {
    return {
      title: 'Loading...',
    }
  },
}
</script>

