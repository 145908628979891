<template>
  <div v-show="!loaded" class="loading-overlay"></div>
  <masonry-infinite-grid
      v-if="renderedGrid"
      ref="grid"
      class="content-list"
      :gap="0"
      v-on:request-prepend="loadPreviousItems"
      v-on:request-append="loadExtraItems"
      :percentage="true"
      :align="'start'"
      :column="columns"
  >
    <div
        class="content-list--item"
        v-for="item in loadedItems"
        :key="item.key"
        :data-grid-groupkey="item.groupKey"
        :id="`griditem${item.key}`"
        @click.capture="$store.dispatch('setPageLocation', {status: $refs.grid.getStatus(), items: loadedItems, page: $router.currentRoute.value.name})"
    >
      <slot name="item" v-bind:item="item"></slot>
    </div>
  </masonry-infinite-grid>
</template>

<script>
import { MasonryInfiniteGrid } from "@egjs/vue3-infinitegrid";
import {mapGetters} from "vuex";


export default {
  name: "InfiniteScroll",
  components: {MasonryInfiniteGrid},
  props: {
    items: {type: Array, required: true},
  },
  data() {
    return {
      perPage: 10,
      loadedItems: [],
      status: null,
      loaded: false,
      renderedGrid: true,
      columns: 4
    };
  },
  watch: {
    items(newItems, oldItems) {
      if (newItems.length === oldItems.length) {
        return;
      }

      this.renderedGrid = false;
      this.loadedItems = this.getItems(0, 10);
      this.$nextTick(() => {
        this.renderedGrid = true;
      });
    }
  },
  computed: {
    ...mapGetters({
      pageLocation: 'getPageLocation'
    })
  },
  mounted() {
    if (
        this.pageLocation &&
        this.pageLocation.status &&
        this.$router.currentRoute.value.meta.usePosition === true &&
        this.$router.currentRoute.value.name === this.pageLocation.page
    ) {
      this.status = this.pageLocation.status;
      setTimeout(() => {
        this.loadedItems = this.pageLocation.items;
        this.$refs.grid.setStatus(this.status);
        this.loaded = true;
        this.$refs.grid.renderItems();
        this.$store.dispatch('setPageLocation', {status: null, items: []});
      }, 200);
    } else {
      this.loadedItems = this.getItems(0, this.perPage);
      this.loaded = true;
    }

    this.checkColumnCount();
    window.addEventListener('resize', this.checkColumnCount);
  },
  methods: {
    checkColumnCount() {
      if (window.innerWidth >= 1200) {
        if ( this.columns !== 4) {
          this.columns = 4;
          this.$refs.grid.renderItems();
        }

        return;
      }
      if (window.innerWidth >= 960) {
        if ( this.columns !== 3) {
          this.columns = 3;
          this.$refs.grid.renderItems();
        }

        return;
      }
      if (window.innerWidth >= 360) {
        if ( this.columns !== 2) {
          this.columns = 2;
          this.$refs.grid.renderItems();
        }

        return;
      }

      if (this.columns !== 1) {
        this.columns = 1;
        this.$refs.grid.renderItems();
      }
    },
    getItems(nextGroupKey, count) {
      const nextItems = [];

      for (let i = 0; i < count; ++i) {
        const nextKey = nextGroupKey * count + i;
        if(typeof this.items[nextKey] === 'undefined') {
          return nextItems;
        }

        nextItems.push({
          ...this.items[nextKey],
          groupKey: nextGroupKey,
          key: nextKey,
        })
      }

      return nextItems;
    },
    loadPreviousItems(e) {
      const nextGroupKey = (e.groupKey || 0) - 1;
      if (nextGroupKey < 0) {
        return;
      }

      this.loadedItems = [...this.getItems(nextGroupKey, this.perPage), ...this.loadedItems];
    },
    loadExtraItems(e) {
      const nextGroupKey = (e.groupKey || 0) + 1;
      if (nextGroupKey * 10 >= Math.ceil(this.items.length / this.perPage) * this.perPage) {
        return;
      }

      this.loadedItems = [...this.loadedItems, ...this.getItems(nextGroupKey, this.perPage)];
    }
  }
}
</script>
