<template>
  <nav id="desktop-navigation" :class="onlyHome ? 'only-home' :''">
    <ul>
      <li>
        <router-link :active-class="'active'" :to="{name : 'popular'}">
          <SvgInline path="icons/home.svg" />
        </router-link>
      </li>
      <li v-if="!onlyHome">
        <button class="reset" v-on:click="openSearchModal">
          <SvgInline path="icons/search.svg" />
        </button>
      </li>
      <li v-if="!onlyHome">
        <router-link :active-class="'active'" :to="{name : 'map'}">
          <SvgInline path="icons/map.svg" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import SvgInline from "../SvgInline";
export default {
  name: "DesktopNavigation",
  components: {SvgInline},
  props : {
    onlyHome: {type: Boolean, required: false},
  },
  methods : {
    openSearchModal() {
      this.$store.dispatch('showSearchModal');
    }
  }
}
</script>
