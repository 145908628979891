<template>
  <div class="guest page" :class="auth ? 'auth' : ''" :id="pageId">
      <slot/>
  </div>
</template>

<script>
export default {
  name: "Guest",
  props : {
    auth : { type : Boolean, required : false},
    pageId : { type : String, required : true }
  }
}
</script>
