<template>
  <form id="register-form" method="POST" v-on:submit.prevent="createUser" v-if="loaded">
    <div class="wrap">
      <h1>Mijn gegevens</h1>

      <div class="error" v-if="errors.length > 0">
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </div>

      <div class="input-group">
        <input class="input-text input-text" :disabled="thirdParty" type="text" required name="firstname" placeholder="Voornaam *" v-model="newUser.firstName">
      </div>

      <div class="input-group">
        <input class="input-text input-text" :disabled="thirdParty" type="text" required name="lastname" placeholder="familienaam *" v-model="newUser.lastName">
      </div>

      <div class="input-group">
        <input class="input-text input-text" type="text" name="totem" placeholder="Totemnaam" v-model="newUser.totemName">
      </div>

      <div class="input-group">
        <v-select class="select-options" :options="units" v-model="newUser.unit" placeholder="Eenheid" />
      </div>
    </div>

    <div class="container">
      <h2>Mijn tak/functie</h2>
    </div>

    <swiper-wrapper :swiper-type="'groupSwiper'">
      <swiper-slide v-for="group in groups" :key="group">
        <AgeGroupItem
            :group="group"
            v-on:toggle="toggle(group.id, 'branch')"
            :active="newUser.branch.includes(group.id)"
        />
      </swiper-slide>
    </swiper-wrapper>

    <div class="container">
      <h2>Mijn interesses</h2>
    </div>

    <swiper-wrapper>
      <swiper-slide v-for="theme in themes" :key="`theme_${theme.id}`">
        <CategoryItem
            :theme="theme"
            v-on:toggle="toggle(theme.id, 'themeInterests')"
            :active="newUser.themeInterests.includes(theme.id)"
        />
      </swiper-slide>
    </swiper-wrapper>

    <div class="wrap">
      <div class="input-group">
        <button class="btn btn-color-pink" type="submit">
          Account aanmaken
        </button>
      </div>
    </div>

  </form>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <LoadPopup v-if="showLoader" />
  </Transition>

</template>

<script>
import AgeGroupItem from "@/molecules/AgeGroupItem";
import CategoryItem from "@/molecules/CategoryItem";
import {mapGetters} from "vuex";
import LoadPopup from "@/components/popups/LoadPopup";
import vSelect from "vue-select";
import units from "@/utils/units";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";

export default {
  name: "RegistrationFormStep2",
  components: {LoadPopup, CategoryItem, AgeGroupItem, vSelect, SwiperWrapper, SwiperSlide},
  data() {
    return {
      errors: [],
      loaded: false,
      fresh : true,
      showLoader : false,
      units : []
    }
  },
  emits: ['registration-process', 'update:modelValue'],
  props: {
    modelValue: {type: Object, required: true},
    thirdParty: {type: Boolean, required: false, default: false}
  },
  computed : {
    ...mapGetters({
      groups : 'groups/all',
      themes: 'themes/all',
    }),

    newUser: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },

  },
  mounted() {
    this.loaded = true;
    this.units = this.sortUnits(units);
    this.checkFreshUser();
  },
  methods : {
    sortUnits(units) {
      return units.sort((a, b) => {
        return a - b;
      });
    },
    toggle(value, key) {
      const index = this.newUser[key].indexOf(value);

      if (index === -1) {
        this.newUser[key] = [...this.newUser[key], value];
      } else {
        this.newUser[key].splice(index, 1);
      }
    },
    toggleItem(item, key) {
        const index = this.newUser[key].indexOf(item.id);
        if (index === -1) {
          this.newUser[key] = [...this.newUser[key], item.id];
        } else {
          this.newUser[key].splice(index, 1);
        }
    },
    createUser() {
      this.showLoader = true;
      this.errors = [];
      this.newUser.thirdParty = this.thirdParty;

      this.$store.dispatch('user/register', this.newUser).then(
          (response) => {
            if(!response.errors){
              this.showLoader = false;
              this.$router.push({name: 'home'});
            }else {
              this.showLoader = false;
              Object.values(response.errors).forEach(error => {
                this.errors.push(error[0])
              });

              window.scrollTo(0,0);
            }

          },
      );
    },
    checkFreshUser() {
      if(this.thirdParty){
        this.newUser = this.$store.getters['user/user'];
      }
    },

  }
}
</script>
