import axios from "axios"
import {apiUrl} from "@/store/api";


export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    setData(state, themes) {
      state.data = themes
    }
  },
  getters: {
    byHandle(state) {
      return (handle) => {
        const pages = state.data.filter((val) => val.handle === handle);
        if (pages.length !== 1) {
          throw 'Page with handle "' + handle + '" not found or found multiple times';
        }

        return pages.pop();
      }
    },
  },
  actions: {
    fetch({ commit }) {
      return axios.get(apiUrl('pages.json'))
        .then(response => {
          commit('setData', response.data.data);

          return response;
        })
    }
  },
};

