<template>
  <Authenticated pageId="poll" v-if="poll">
    <div class="container">
      <span class="subtitle">Een vraag</span>
      <h1 class="title">
        {{ poll.question }}
      </h1>
      <button
          class="reset answer"
          v-for="(answer, i) in poll.answers"
          :class="[`answer-${i + 1}`, {'active' : selectedAnswer === answer }]"
          v-on:click="selectedAnswer = answer"
          :key="`answer_${i}`">
        <SvgInline path="icons/shape.svg" />
        {{ answer }}
      </button>

      <button
          class="submit btn btn-color-pink"
          v-on:click="submitAnswer"
      >
        Bevestigen
      </button>
    </div>

    <div v-if="answered" class="thank-you">
      <div class="container">

        <a class="exit-button"
           v-on:click.prevent="this.$router.go(-1);">
          <SvgInline path="icons/exit.svg" />
        </a>

        <p>{{ thankYou.text }}</p>
        <a class="btn btn-color-light-red"
           v-if="thankYou.button && thankYou.button.link"
           :href="thankYou.button.type === 'site' ? $router.resolve({name : 'home'}).href : thankYou.button.link">
          {{ thankYou.button.text }}
        </a>
      </div>
    </div>

  </Authenticated>
</template>

<script>
import {mapGetters} from "vuex";
import Authenticated from "@/layouts/Authenticated";
import axios from "axios";
import {baseUrl} from "@/store/api";
import SvgInline from "@/components/SvgInline";

export default {
  name: "SinglePoll",
  components: {SvgInline, Authenticated},
  props: {
    poll: {type: Object, required: true}
  },
  data(){
    return {
      selectedAnswer : null,
      answered : false,
    }
  },

  mounted() {
    this.$route.meta.background = 'green';
  },
  computed: {
    thankYou(){
      return this.poll.thankYouMessage;
    },
  },
  methods: {
    submitAnswer() {
      axios.post(baseUrl('answer-poll'), {
        question: this.poll.id,
        answer: this.selectedAnswer
      }).then(() => {
        this.answered = true;
      });
    },
  },
  metaInfo() {
    return {
      title : this.poll.question
    }
  }
}
</script>
