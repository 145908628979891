import axios from "axios"
import {apiUrl, baseUrl} from "@/store/api";
import {Article} from "@/store/_classes";


export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    setData(state, articles) {
      state.data = articles.map(a => new Article(a));
    },
  },
  getters: {
    bySlug(state) {
      return (slug) => {
        const articles = state.data.filter((val) => val.slug === slug || val.slugWithoutId === slug);
        if (articles.length !== 1) {
          throw 'Article with slug "' + slug + '" not found or found multiple times';
        }

        return articles.pop();
      }
    },
    related(state) {
      return (article, count = 3) => {
        const articles = state.data.filter(
          (val) => val.theme.id === article.theme.id && val.id !== article.id
        );

        if (!articles) {
          return [];
        }

        articles.forEach((art) => {
          art.tagCount = 0;
          art.tags?.forEach((tag) => {
            if (article.tags.map(val => val.id).includes(tag.id)) {
              art.tagCount++;
            }
          });
        });

        articles.sort(function (a, b) { return b.tagCount -  a.tagCount; });

        return articles.slice(0, count);
      }
    },
    all(state ) {
      return state.data;
    },
    filtered(state, getters, rootState) {
      return (type = 'default') => {
        const themeFilter = rootState.preferences.themeFilter;
        const tagFilter = rootState.preferences.tagFilter;
        const selectGroup = rootState.preferences.selectedGroup;
        const groupFilter = rootState.preferences.groupFilter;

        if(Array.isArray(selectGroup)){
          if(selectGroup.length !== 0 && !selectGroup.includes('articles')) return [];
        }

        let filteredData = state.data
          .filter(article => themeFilter.length === 0 || themeFilter.includes(article.theme.id))
          .filter(article => tagFilter.length === 0 || article.tags.some((tag) => tagFilter.includes(tag.id)))
          .filter(article => groupFilter.length === 0 ||
            (article.ageGroups && article.ageGroups.some((group) => groupFilter.includes(group.id)))
          )



        if (!['home', 'popular', 'articles'].includes(type)){
          return filteredData;
        }

        return [
          ...filteredData.filter(val => val.featured === true),
          ...filteredData.filter(val => val.featured === false),
        ];
      }
    },
    forYou(state, getters, rootState) {
      let articles = [];

      articles = [...articles, ...state.data.filter(article =>
        rootState.user.user.themeInterests.includes(article.theme?.id)
      )];

      articles.filter(article => rootState.user.user.branch.length === 0 ||
        (article.ageGroups && article.ageGroups.some((group) => rootState.user.user.branch.includes(group.id)))
      );

      return [
        ...articles.filter(val => val.featured === true),
        ...articles.filter(val => val.featured === false),
      ];
    },
    searched(state, getters, rootState) {
      return () => {
        const searchTerm = rootState.search.searchTerm;
        const themeSearch = rootState.search.themeSearch;
        const tagSearch = rootState.search.tagSearch;
        const selectGroup = rootState.search.selectedGroup;
        const groupSearch = rootState.search.groupSearch;

        if(Array.isArray(selectGroup)){
          if(selectGroup.length !== 0 && !selectGroup.includes('articles')) return [];
        }

         let searchedData = state.data
           .filter(article => tagSearch.length === 0 || article.tags.some((tag) => tagSearch.includes(tag.id)))
           .filter(article => groupSearch.length === 0 ||
             (article.ageGroups && article.ageGroups.some((group) => groupSearch.includes(group.id)))
           )

        if(Object.values(themeSearch).length > 0){
          searchedData = searchedData.filter(article => themeSearch.length === 0 || themeSearch.includes(article.theme.id))
        }


        if(searchTerm){
          searchedData = searchedData
            .filter(article =>
              article.title && article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
              article.author && (article.author.title && article.author.title.toLowerCase().includes(searchTerm.toLowerCase()) || (article.author.fullname && article.author.fullname.toLowerCase().includes(searchTerm.toLowerCase()))) ||
              article.summary && article.summary.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }

        return searchedData.filter(article => groupSearch.length === 0 || (article.ageGroup && groupSearch.includes(article.ageGroup.id)));

      }
    }
  },
  actions: {
    fetch({ commit }) {
      return axios.get(apiUrl('articles.json'))
        .then(response => {
          commit('setData', response.data.data);

          return response;
        })
    },
    async addView({ commit }, item) {
      await axios.post(baseUrl('add-view'), {id: item.id });
    },
  },
};

