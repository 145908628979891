<template>
  <Guest auth pageId="intro">
    <div class="container">
      <img class="banner img-auth" src="../assets/images/fos-open-scouting.svg" alt="img">
      <div class="error centered-error" v-if="error">Er liep iets fout tijdens het inloggen</div>
      <router-link class="btn btn-color-kaki" :to="{ name : 'login'}">Login </router-link>
      <router-link class="btn btn-color-pink" :to="{ name : 'register'}">Maak een account </router-link>
      <router-link class="link-home" :to="{ name : authenticated ? 'home' : 'popular'}">Ik wil meteen lezen <SvgInline path="icons/auth-arrow.svg" /></router-link>
    </div>

  </Guest>
</template>

<script>
import Guest from "../layouts/Guest";
import SvgInline from "../components/SvgInline";
export default {
  name: 'Index',
  components: {SvgInline, Guest},
  data() {
    return {
      error: null
    }
  },
  methods: {
    exitLoginPage() {
      if(window.history.length){
        this.$router.back();
      } else {
        this.$router.push({ name : 'account'})
      }
    }
  },
  metaInfo() {
    return {
      title: 'Welkom',
    }
  },
};
</script>
