<template>
  <Popup :title="title" v-on:exit="this.$store.dispatch(type !== 'board' ? 'showRemovePopup' : 'showRemoveBoardPopup')">
    <p v-if="text">{{ text }}</p>
    <button
        class="btn btn-color-light-red"
        v-on:click="type !== 'board'
        ? removeItem()
        : removeBoard()">
      Verwijderen
    </button>
    <button class="reset cancel" v-on:click="this.$store.dispatch(type !== 'board' ? 'showRemovePopup' : 'showRemoveBoardPopup')">
      annuleren
    </button>
  </Popup>
</template>

<script>
import Popup from "@/components/popups/Popup";

export default {
  name: "RemovePopup",
  components: {Popup},
  props : {
    text : { type : String, required : false },
    item : { type : Object, required: true },
    title : { type : String, required : true},
    type : { type : String, required : false }
  },
  methods : {
    removeItem() {
      this.$store.dispatch('user/removeItem', {item : this.item.item.id, board : this.item.board.id});
    },
    removeBoard() {
      this.$store.dispatch('preferences/removeBoard', this.item);
    }
  }
}
</script>
