<template>
  <div class="animate__animated animate__fadeIn pop-up_overlay" v-on:click.self="$emit('exit')">
      <div class="animate__animated animate__fadeInUp pop-up">
        <h2>{{ title }}</h2>
        <button class="reset exit" v-on:click.prevent="$emit('exit')">
          <SvgInline path="icons/exit.svg" />
        </button>
        <slot/>
      </div>
  </div>

</template>

<script>
import SvgInline from "@/components/SvgInline";

export default {
  name: "Popup",
  components: {SvgInline},
  props : {
    title : { type : String, required : true},
  }
}
</script>
