import axios from "axios"
import {apiUrl} from "@/store/api";


export default {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    setData(state, themes) {
      state.data = themes
    }
  },
  getters: {
    all(state) {
      return state.data;
    }
  },
  actions: {
    fetch({ commit }) {
      return axios.get(apiUrl('tags.json'))
        .then(response => {
          commit('setData', response.data.data);

          return response;
        })
    }
  },
};

