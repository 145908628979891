<template>
  <Guest auth pageId="login-page">
    <div class="container">
      <button class="reset back-arrow" v-on:click="goBack">
        <SvgInline path="icons/back-button.svg" />
      </button>

      <img class="banner img-register" src="@/assets/images/owl-wing-open.svg" alt="image static">

      <form id="register-form" method="POST" v-on:submit.prevent="doLogin">
        <div class="wrap">
          <h1>Inloggen</h1>

          <div class="error" v-if="error">
            <p>{{ error }}</p>
          </div>

          <div class="input-group">
            <input class="input-text input-text" type="email" required name="email" placeholder="E-mailadres *" v-model="authUser.username">
          </div>

          <div class="input-group">
            <input class="input-text input-text" type="password" required name="password" placeholder="Wachtwoord *" v-model="authUser.password">
          </div>
        </div>

        <button type="submit" class="btn btn-color-kaki">Log in</button>

        <router-link :to="{name : 'password.reset'}" class="reset reset-password">Wachtwoord vergeten?</router-link>
      </form>

      <SocialLogin />

    </div>
  </Guest>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <LoadPopup v-if="showLoader" />
  </Transition>

</template>

<script>
import Guest from "../layouts/Guest";
import SvgInline from "../components/SvgInline";
import LoadPopup from "@/components/popups/LoadPopup";
import SocialLogin from "@/components/SocialLogin";

export default {
  name: 'Login',
  components: {SvgInline, Guest, LoadPopup, SocialLogin},
  data() {
    return {
      authUser: {
        username: null,
        password: null,
      },
      error : null,
      showLoader : false,
    }
  },
  methods : {
    goBack() {
      if(window.history && window.history.back){
        this.$router.back();
      } else {
        this.$router.push({ name : 'account'})
      }
    },
    doLogin () {
      this.showLoader = true;
      this.$store.dispatch('user/login', this.authUser).then(
          () => {
            this.$store.dispatch('user/csrfToken');
            this.$store.dispatch('preferences/fetchBoards');
            this.showLoader = false;
            this.$router.push({name : 'home'});
          },
          error => {
            this.showLoader = false;
            this.error = 'Ongeldig e-mail adres of wachtwoord'
          }
      );
    },
  },
  metaInfo() {
    return {
      title: 'Inloggen',
    }
  },
};
</script>
