<template>
  <Authenticated
      pageId="articles"
      withFilter
  >
    <div class="container">
      <h1 class="text-align-center">Lezen</h1>
    </div>

    <swiper-wrapper>
      <swiper-slide v-for="theme in themes" :key="`theme_${theme.id}`">
        <CategoryItem
            :theme="theme"
            v-on:toggle="this.$store.dispatch('preferences/toggleThemeFilter', theme)"
            :active="activeThemes.includes(theme.id) || themeFilter.length === 0"
        />
      </swiper-slide>
    </swiper-wrapper>

    <div class="container">
      <div class="nav-content">
        <Navigator />
        <button v-if="appliedFilters" class="reset remove-filters" v-on:click="this.$store.dispatch('preferences/resetFilters')">
          <SvgInline path="icons/exit.svg" />
          actieve filters verwijderen
        </button>
      </div>

      <div class="content" :class="{'hidden' : showFilter || showSearchModal }">
        <InfiniteScroll v-if="articles.length > 0" :items="articles">
          <template v-slot:item="{ item }">
            <ArticleItem :data="item" />
          </template>
        </InfiniteScroll>

        <div v-else class="no-results">
          Geen resultaten gevonden op basis van de huidige filters
        </div>
      </div>

    </div>
  </Authenticated>
</template>

<script>
import ArticleItem from "@/organisms/ArticleItem";
import CategoryItem from "@/molecules/CategoryItem";
import Authenticated from "@/layouts/Authenticated";
import {mapGetters, mapState} from "vuex";
import Navigator from "@/components/layout/Navigator";
import InfiniteScroll from "@/molecules/InfiniteScroll";
import SvgInline from "@/components/SvgInline";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";

export default {
  name: "Articles",
  components: {SvgInline, InfiniteScroll, Navigator, Authenticated, ArticleItem, CategoryItem, SwiperWrapper, SwiperSlide},
  data() {
    return {
      showAllTags : false,
      swiperWidth : false,
      maxShownTags: 7,
      desktopWidth : window.innerWidth,
      windowWidth : 0,
    }
  },
  mounted() {
    window.addEventListener('load', this.setDesktopWidth);
    window.addEventListener('resize', this.setDesktopWidth);
    window.addEventListener('resize', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      themes: 'themes/all',
      themeFilter: 'preferences/themeFilter',
      activeThemes: 'preferences/themeFilter',
      activeTags: 'preferences/tagFilter',
      showFilter: 'showFilter',
      showSearchModal : 'showSearchModal'
    }),

    articles() {
      return this.$store.getters['articles/filtered']('articles')
    },

    slidesPerView(){
      const windowWith = (this.windowWidth !== 0 ? this.windowWidth : window.innerWidth);

      const slides =  (windowWith / 175).toFixed(1).toString();
      let partInt = slides.substring(0, slides.indexOf('.'));

      return partInt + '.5';
    }
  },
  methods : {
    setDesktopWidth(e) {
      this.desktopWidth = e.currentTarget.innerWidth > 500;
    },
    handleScroll() {
      this.windowWidth = window.innerWidth;
    }
  },
  metaInfo() {
    return {
      title : 'Lezen',
      meta : [
        { property : 'og:title' , content : 'Scoutsmap - lezen'},
        { property : 'og:description' , content : '' },
        { property : 'og:locale' , content : 'nl_BE' },
        { property : 'og:type' , content : 'website' },
        { property : 'og:url' , content : window.location.href },
      ]
    }
  },
}
</script>
