import store from "@/store/index";
class BaseClass {
  constructor(data) {
    for (let key in data) {
      this[key] = data[key];
    }
  }
}

export class Article extends BaseClass {}
export class Game extends BaseClass {}
export class User extends BaseClass {
  hasFavourited(item) {
    const boards = store.getters["preferences/boards"];

    let items = [];
    Object.values(boards).forEach(board => {
      items = [...items, board.items];
    });

    if(Object.values(items).length) {
      return items[0].map(val => val.slug).includes(item.slug)
    }

    return false;

  }

  hasLiked(item) {
    const likedItems = Object.values(this.likedItems || []);
    if (likedItems.includes(item.id)) {
      return true;
    }

    return false;
  }
}

export const emptyGame = {
  name: '',
  type: null,
  tags: [],
  theme: null,
  media: [],
  properties: {
    location: {
      value: 'forest',
      label: 'bos'
    },
    age: {
      min: 7,
      max: 18
    },
    players: {
      min: 5,
      max: 70
    },
    time: {
      min: {
        value: null,
        label: null
      },
      max: {
        value: null,
        label: null
      }
    }
  },
  summary: null,
  content: {
    material: null,
    downloads: [],
    preparation: null,
    story: null,
    flow: null,
  }
}
