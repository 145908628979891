<template>
  <Authenticated
      page-id="profile"
      class-name="settings"
      :back-button="{ route : 'settings', title : 'Openbaar profiel'}"
      reset
  >

    <div class="container">
      <div class="profile-picture">

        <SvgInline path="owl-wing-open.svg" class="placeholder"/>

        <template v-if="false">
          <SvgInline path="misc/mask.svg"/>
          <img :src="require('@/assets/images/static/scout-1.jpg')" alt="FOS Open Scouting"/>

          <button class="edit reset">
            <SvgInline path="icons/edit.svg"/>
          </button>
        </template>
      </div>

      <div class="error" v-if="errors.length > 0">
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </div>
    </div>

    <form @submit.prevent="updateUser">
      <div class="container">
        <div class="input-group">
          <input class="input-text input-text" type="text" required name="fiirstNAme" placeholder="Voornaam *"
                 v-model="updateUserObject.firstName">
        </div>

        <div class="input-group">
          <input class="input-text input-text" type="text" required name="lastName" placeholder="Familienaam *"
                 v-model="updateUserObject.lastName">
        </div>
        <div class="input-group">
          <input class="input-text input-text" type="text" name="totem" placeholder="Totemnaam"
                 v-model="updateUserObject.totemName">
        </div>

        <div class="input-group">
          <v-select class="select-options" :options="units" v-model="updateUserObject.unit" placeholder="Eenheid" />
        </div>
      </div>

      <div class="container">
        <h2>Mijn interesses</h2>
      </div>

      <swiper-wrapper>
        <swiper-slide v-for="theme in themes" :key="`theme_${theme.id}`">
          <CategoryItem
              :theme="theme"
              v-on:toggle="toggleTheme(theme.id)"
              :active="updateUserObject.themeInterests.includes(theme.id)"
          />
        </swiper-slide>
      </swiper-wrapper>

      <div class="container">
        <h2>Mijn tak/functie</h2>
      </div>

      <swiper-wrapper :swiper-type="'groupSwiper'">
        <swiper-slide v-for="group in groups" :key="group">
          <AgeGroupItem
              :group="group"
              :active="updateUserObject.branch.includes(group.id)"
              v-on:toggle="toggleGroup(group.id)"
          />
        </swiper-slide>
      </swiper-wrapper>


      <div class="container">
        <div class="input-group">
          <button class="btn btn-color-pink" type="submit" >
            Profiel updaten
          </button>
        </div>
      </div>
    </form>

    <div class="container">
      <div class="content contribution-content">
        <h2>Mijn bijdrages</h2>

        <nav class="navigator contribution-navigator">
          <button v-on:click="type = 'games'" :class="{ 'active' : type === 'games'}" class="reset">Mijn spelen</button>
          <button v-on:click="type = 'articles'" :class="{ 'active' : type === 'articles'}" class="reset">Mijn
            artikels
          </button>
        </nav>

        <InfiniteScroll v-if="type === 'games'" :items="games">
          <template v-slot:item="{ item }">
            <GameItem  :data="item" />
          </template>
        </InfiniteScroll>

        <template v-else>
          <template v-if="user.role === 'admin'">
            <ul v-if="articles" class="articles">
              <li v-for="article in articles" :key="`article_${article.id}`">
                <ArticleItem :data="article"/>
              </li>
            </ul>
          </template>

          <div v-else class="apply-for-admin">
            <p>Wilt u ook artikels schrijven voor FOS?</p>
            <router-link class="reset btn bg-color-light-red" :to="{name : 'volunteer'}">Klik hier</router-link>
          </div>
        </template>
      </div>
    </div>
  </Authenticated>

  <div class="errorMessage" v-if="errorMessage">
    <span>
      {{ errorMessage }}
      <button class="reset exit" v-on:click="this.$store.dispatch('setErrorMessage', null);">
        <SvgInline path="icons/exit.svg" />
      </button>
    </span>

  </div>

  <Transition
      name="fade-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate_animated animate__fadeOut">
    <LoadPopup v-if="showLoader" />
  </Transition>
</template>

<script>
import Authenticated from "../../layouts/Authenticated";
import SvgInline from "../../components/SvgInline";
import {mapGetters} from "vuex";
import ArticleItem from "../../organisms/ArticleItem";
import GameItem from "@/organisms/GameItem";
import CategoryItem from "@/molecules/CategoryItem";
import AgeGroupItem from "@/molecules/AgeGroupItem";
import InfiniteScroll from "@/molecules/InfiniteScroll";
import LoadPopup from "@/components/popups/LoadPopup";
import vSelect from "vue-select";
import units from "@/utils/units";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";

export default {
  name: "Profile",
  components: {InfiniteScroll, AgeGroupItem, CategoryItem, GameItem, ArticleItem, SvgInline, Authenticated, LoadPopup, vSelect, SwiperWrapper, SwiperSlide},
  data() {
    return {
      windowWidth: 0,
      type: 'games',
      errors: [],
      showLoader: false,
      units : [],
      updateUserObject : {
        firstName : null,
        lastName : null,
        totemName : null,
        unit: null,
        themeInterests : [],
        branch : []
      }
    }
  },
  computed: {
    ...mapGetters({
      games: 'games/byUser',
      themes: 'themes/all',
      groups : 'groups/all',
      groupSwiperPositionCenter : "groupSliderPositionCenter",
      errorMessage : 'errorMessage',
    }),
  },
  mounted() {
    this.updateUserObject.firstName = this.user.firstName;
    this.updateUserObject.lastName = this.user.lastName;
    this.updateUserObject.totemName =  this.user.totemName;
    this.updateUserObject.unit = this.user.unit;
    this.updateUserObject.themeInterests = this.user.themeInterests;
    this.updateUserObject.branch = this.user.branch;

    this.units = this.sortUnits(units);

  },
  methods: {
    toggleTheme(value) {
      const index = this.updateUserObject.themeInterests.indexOf(value);

      if (index === -1) {
        this.updateUserObject.themeInterests = [...this.updateUserObject.themeInterests, value];
      } else {
        this.updateUserObject.themeInterests.splice(index, 1);
      }
    },

    toggleGroup(value) {
      const index = this.updateUserObject.branch.indexOf(value);

      if (index === -1) {
        this.updateUserObject.branch = [...this.updateUserObject.branch, value];
      } else {
        this.updateUserObject.branch.splice(index, 1);
      }
    },

    sortUnits(units) {
      return units.sort((a, b) => {
        return a - b;
      });
    },

    updateUser() {
      this.errors = [];
      this.showLoader = true;

      this.$store.dispatch('user/updateProfile', this.updateUserObject).then(
          (response) => {
            if (!response.errors) {
              this.showLoader = false;
              this.$router.push({name: 'settings.profile'});
            } else {
              this.showLoader = false;
              Object.values(response.errors).forEach(error => {
                this.errors.push(error[0])
              });

              window.scrollTo(0, 0);
            }
          },
      );
    }
  },
  metaInfo() {
    return {
      title: 'Mijn profiel',
    }
  },
}
</script>
