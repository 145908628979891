<template>
  <Authenticated
      pageId="games"
      :back-button="{ route : 'popular', title : 'Spelen', force : true}"
      with-filter
  >
    <CreateGameModal v-show="showGameModal" />

    <div class="container">
      <nav class="navigator game-navigator">
        <button v-on:click="changeType('short')" :class="{ 'active' : type === 'short'}" class="reset">Korte spelen</button>
        <button v-on:click="changeType('activity')" :class="{ 'active' : type === 'activity'}" class="reset">Activiteiten</button>
        <button v-on:click="changeType('task')" :class="{ 'active' : type === 'task'}" class="reset">Opdrachten</button>
      </nav>

      <div class="content" :class="{'hidden' : showFilter || showSearchModal }">
        <template v-if="type !== 'task'">
          <InfiniteScroll v-if="games.length > 0" :items="games">
            <template v-slot:item="{ item }">
              <GameItem :data="item" />
            </template>
          </InfiniteScroll>

          <div v-else class="no-results">
            Geen resultaten gevonden op basis van de huidige filters
          </div>

        </template>

        <ul v-else class="tasks">
          <li v-for="(game, i) in games.slice(0,10)" :key="`game-${i}`">
            <span>{{ game.title }}</span>
          </li>
        </ul>
      </div>

      <button v-if="authenticated" class="reset add-game-button" v-on:click="toggleShowModal">
        <SvgInline path="icons/add-game.svg"/>
      </button>
    </div>

  </Authenticated>
</template>

<script>
import Authenticated from "@/layouts/Authenticated";
import SvgInline from "@/components/SvgInline";
import CreateGameModal from "@/components/createGame/CreateGameModal";
import {mapGetters} from "vuex";
import GameItem from "../organisms/GameItem";
import InfiniteScroll from "@/molecules/InfiniteScroll";

export default {
  name: "Games",
  components: {InfiniteScroll, GameItem, CreateGameModal, SvgInline, Authenticated },
  data() {
    return {
      type : null,
      seed: null,
    }
  },
  methods : {
    checkForGameToCreate()
    {
      if(this.$route.params.showPopup){
        this.showGameModal = true;
      }
    },
    changeType(type) {
      if (!['short', 'activity', 'task'].includes(type)) {
        type = 'activity';
      }

      if (type === this.type && type === 'task') {
        this.seed = Math.random();
      }

      this.type = type;
      window.location.hash = '#' + type;
    },
    toggleShowModal() {
      return this.$store.dispatch('showGameModal');
    },
    shuffle(array, seed) {
      let m = array.length, t, i;

      while (m) {
        i = Math.floor(this.random(seed) * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
        ++seed
      }

      return array;
    },
    random(seed) {
      const x = Math.sin(seed++) * 10000;
      return x - Math.floor(x);
    }
  },
  beforeMount() {
    this.seed = Math.random();
    if (this.$route.hash) {
      this.changeType(this.$route.hash.replace('#', ''));
    } else {
      this.changeType('activity');
    }
  },
  mounted() {
    this.checkForGameToCreate();
  },
  metaInfo() {
    return {
      title: 'Spelen',
    }
  },
  computed : {
    ...mapGetters({
      filteredByType: 'games/filteredByType',
      showFilter : 'showFilter',
      showSearchModal : 'showSearchModal',
      showGameModal : 'showGameModal'
    }),

    games() {
      const games = this.filteredByType(this.type);
      return this.type === 'task' ? this.shuffle(games, this.seed) : games;
    },

    showGameModal() {
      return this.$store.state.showGameModal
    },
  }
}
</script>

<style scoped>

</style>
