import Intro from './Intro.vue';
import Register from './Register.vue';
import Articles from './Articles.vue';
import SingleArticle from './SingleArticle.vue';
import SinglePoll from './SinglePoll.vue';
import Games from "./Games";
import SingleGame from "./SingleGame";
import Popular from "./Popular";
import Home from './Home.vue';
import Map from './Map.vue';
import SingleBoard from "./SingleBoard";
import Settings from "../components/layout/Settings";
import Profile from "./settings/Profile";
import AccountSettings from "./settings/AccountSettings";
import StaticPage from "./settings/StaticPage";
import SearchResults from "./SearchResults";
import ExampleGame from "@/views/ExampleGame";
import NotFound from "@/views/NotFound";
import Login from "@/views/Login";
import ResetPassword from "@/views/reset-password/ResetPassword";
import SetNewPassword from "@/views/reset-password/SetNewPassword";

export default {
  Intro,
  Register,
  Login,
  Articles,
  SingleArticle,
  SinglePoll,
  Games,
  SingleGame,
  Popular,
  Home,
  Map,
  SingleBoard,
  Settings,
  Profile,
  AccountSettings,
  StaticPage,
  SearchResults,
  ExampleGame,
  NotFound,
  ResetPassword,
  SetNewPassword
};
