<template>
  <div class="load-popup">
    <Loader />
  </div>
</template>

<script>
import Loader from "@/components/layout/Loader";
export default {
  name: "LoadPopup",
  components: {Loader}
}
</script>