<template>
  <Authenticated
      page-id="settings"
      class-name="settings"
      :back-button="{ route : 'map', title : 'Instellingen'}"
      reset
  >
    <div class="setting-content-wrapper">
      <ul class="setting-links">
        <li><button class="reset" v-on:click="goTo('settings.profile', authenticated)">Openbaar profiel</button></li>
        <li><router-link :to="{ name : 'settings.contact'}">Contact & noodlijn</router-link></li>
        <li><router-link :to="{ name : 'settings.important-files'}">Nuttige bestanden</router-link></li>
        <li><router-link :to="{ name : 'settings.about'}">Over</router-link></li>
        <li><router-link :to="{ name : 'volunteer'}">Vrijwilliger worden</router-link></li>
        <li  v-if="!authenticated"><router-link :to="{ name : 'account'}">Inloggen of registreren</router-link></li>
        <li v-else><span class="logout-button" v-on:click="doLogout">Uitloggen</span></li>
      </ul>

      <ul class="setting-logos">
        <li><SvgInline path="logos/vlaanderen.svg"/></li>
        <li><SvgInline path="logos/wosm.svg"/></li>
        <li><SvgInline path="logos/wagggs.svg"/></li>
        <li><SvgInline path="logos/wiewat.svg"/></li>
      </ul>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "../../layouts/Authenticated";
import SvgInline from "@/components/SvgInline.vue";

export default {
  name: "Settings",
  components: {SvgInline, Authenticated},
  metaInfo : {
    title : 'Instellingen'
  },
  methods : {
    async doLogout() {
      await this.$store.dispatch('user/logout');
      this.$router.push({ name: 'account'});
    },
    goTo(link, authenticated){
      return authenticated
          ? this.$router.push({ name : link})
          : this.$store.dispatch('showLoginPopup')
    }
  }
}
</script>

<style scoped>

</style>
