<template>
  <div id="create-game-modal" class="fullscreen" :class="`color-${step}`">
    <button v-on:click="toggleModal" class="reset exit-button" :class="{'darker' : [4,6,8].includes(step)}">
      <SvgInline path="icons/exit.svg"></SvgInline>
    </button>

    <template v-if="step === 0">
      <button v-on:click="this.$store.dispatch('setStep', 1)" class="reset create-game-button">
        <SvgInline path="icons/add.svg"/>
        Voeg een spel toe
      </button>
    </template>

    <Step1 v-else-if="step === 1" v-on:gameProcess="triggerStep(2)"/>
    <Step2 v-else-if="step === 2" v-on:gameProcess="triggerStep(3)"/>
    <Step3 v-else-if="step === 3" v-on:gameProcess="triggerStep(4)"/>
    <Step4 v-else-if="step === 4" v-on:gameProcess="triggerStep(5)"/>
    <Step5 v-else-if="step === 5" v-on:gameProcess="triggerStep(6)"/>
    <Step6 v-else-if="step === 6" v-on:gameProcess="triggerStep(7)"/>
    <Step7 v-else-if="step === 7" v-on:gameProcess="triggerStep(8); triggerStep8 = true"/>
    <Step8 v-else-if="step === 8" />


    <div class="img-frame" :class="`frame-${step}`">
      <SvgInline path="illustrations/sun.svg"/>
      <SvgInline path="illustrations/blue.svg"/>
      <SvgInline path="illustrations/cloud.svg"/>
    </div>

    <div class="create-game-navigator" :class="{'darker' : [4,6,8].includes(step), 'fixed' : [6,8].includes(step)}" v-if="step > 0">
      <ul>
        <li v-for="(index) in 8" :key="`step_${index}`" :class="{'active' : step === index }" :style="{'pointer-events:' : (index > step ? 'none' : 'auto')}">
          <svg v-on:click="this.$store.dispatch('setStep', index)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <path data-name="Path 171" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z"/>
          </svg>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgInline from "@/components/SvgInline";
import Step1 from "@/components/createGame/steps/Step1";
import Step2 from "@/components/createGame/steps/Step2";
import Step3 from "@/components/createGame/steps/Step3";
import Step4 from "@/components/createGame/steps/Step4";
import Step5 from "@/components/createGame/steps/Step5";
import Step6 from "@/components/createGame/steps/Step6";
import Step7 from "@/components/createGame/steps/Step7";
import Step8 from "@/components/createGame/steps/Step8";
import {mapGetters} from "vuex";

export default {
  name: "CreateGameModal",
  components: {Step8, Step7, Step6, Step5, Step4, Step3, Step1, Step2, SvgInline},
  data() {
    return {
      triggerStep8 : false
    }
  },
  mounted() {
    if (this.step > 7) {
      this.triggerStep8 = true;
    }
  },
  methods : {
    toggleModal() {
      this.$store.dispatch('showGameModal');
    },
    triggerStep(step) {
      if(this.triggerStep8){
        return this.$store.dispatch('setStep', 8)
      }

      this.$store.dispatch('setStep', step)
    }
  },
  computed :{
    ...mapGetters({
      game : 'newGame',
      step : 'step',
    })
  }

}
</script>
