<template>
  <Guest auth pageId="register">

    <div class="container">
      <button class="reset back-arrow" v-on:click="goBack">
        <SvgInline path="icons/back-button.svg" />
      </button>
      <img class="banner img-register" src="@/assets/images/owl-wing-open-right.svg" alt="image static">
    </div>

    <template v-if="step === 1">
      <RegistrationFormStep1 :model-value="newUser" v-on:registration-process="step++" />
    </template>

    <template v-else>
      <RegistrationFormStep2 :model-value="newUser" :third-party="this.$route.params.thirdParty" />
    </template>
  </Guest>
</template>

<script>
import Guest from "../layouts/Guest";
import RegistrationFormStep2 from "../organisms/forms/RegistrationFormStep2";
import RegistrationFormStep1 from "../organisms/forms/RegistrationFormStep1";
import SvgInline from "../components/SvgInline";

export default {
  name: 'Register',
  components: {SvgInline, RegistrationFormStep2, Guest, RegistrationFormStep1},
  data() {
    return {
      step : 1,
      newUser : {
        username : null,
        firstName : null,
        lastName: null,
        totemName : null,
        unit : null,
        password : null,
        branch : [],
        themeInterests : [],
      }
    }
  },
  beforeMount() {
    if(this.$route.params.thirdParty){
      this.step = 2;
    }
  },
  methods : {
    goBack() {
      if(window.history.length){
        this.$router.back();
      } else {
        this.$router.push({ name : 'home'})
      }
    },
  },
  metaInfo() {
    return {
      title: 'Maak een account',
    }
  },
};
</script>
