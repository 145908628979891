<template>
  <nav class="bottom-navigation" id="main-navigation">
    <ul>
      <li>
        <router-link :active-class="'active'" :to="{name : 'popular'}">
          <SvgInline path="icons/home.svg" />
        </router-link>
      </li>
      <li>
        <button class="reset search" v-on:click="$store.dispatch('showSearchModal')"
                :class="{'active' : checkSearchRoute() }"
        >
          <SvgInline path="icons/search.svg" />
        </button>
      </li>
      <li>
        <router-link :active-class="'active'" :to="{name : 'map'}">
          <SvgInline path="icons/map.svg" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import SvgInline from "../SvgInline";
export default {
  name: "BottomNavigation",
  components: {SvgInline},
  methods : {
    checkSearchRoute(){
      const searchRoutes = ['search-results'];
      return searchRoutes.includes(this.$router.currentRoute.value.name);
    }
  },
}
</script>
