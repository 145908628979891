<template>
  <div id="loader">
    <div class="dot-pulse"></div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>