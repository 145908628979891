<template>
  <div class="filter">
    <h1>Filter</h1>

    <div class="filter-buttons">
      <button
          v-on:click="submitFilter"
          class="btn btn-color-light-red submit-filter-button">
        Toepassen
      </button>

      <button v-show="checkFilters" class="reset reset-filter-button" v-on:click="resetFilter">
        <SvgInline path="icons/exit.svg" />
        wis filters
      </button>
    </div>

    <button v-on:click="toggleFilter" class="exit-button reset">
      <Svg path="icons/exit.svg" />
    </button>

    <swiper-wrapper :title="'Mijn interesses'">
      <swiper-slide v-for="theme in themes" :key="`theme_${theme.id}`">
        <CategoryItem
            :theme="theme"
            v-on:toggle="toggle(theme.id, 'themeFilter')"
            :active="filter.themeFilter.includes(theme.id) || filter.themeFilter.length === 0"
        />
      </swiper-slide>
    </swiper-wrapper>

    <div class="interest-options-as-tags">
      <div class="container">
        <ul class="tag-options">
          <li v-for="tag in tags" :key="`tag_${tag.id}`">
            <TagItem
                :tag="tag"
                v-on:toggle="toggle(tag.id, 'tagFilter')"
                :active="filter.tagFilter.includes(tag.id) || filter.tagFilter.length === 0"
            />
          </li>
        </ul>
      </div>
    </div>

    <swiper-wrapper :swiper-type="'groupSwiper'" :title="'Mijn tak/functie'">
      <swiper-slide v-for="group in groups" :key="group">
        <AgeGroupItem
            :group="group"
            v-on:toggle="toggle(group.id, 'groupFilter')"
            :active="filter.groupFilter.includes(group.id) || filter.groupFilter.length === 0"
        />
      </swiper-slide>
    </swiper-wrapper>

  </div>

</template>

<script>
import Svg from "../SvgInline";
import AgeGroupItem from "../../molecules/AgeGroupItem";
import {mapGetters} from "vuex";
import CategoryItem from "../../molecules/CategoryItem";
import TagItem from "../../molecules/TagItem";
import SvgInline from "@/components/SvgInline";
import SwiperWrapper from "@/components/SwiperWrapper";
import { SwiperSlide} from "swiper/vue/swiper-vue";

export default {
  name: "Filter",
  components: {
    SvgInline,
    TagItem,
    AgeGroupItem,
    Svg,
    CategoryItem,
    SwiperWrapper,
    SwiperSlide
  },
  data() {
    return {
      windowWidth : 0,
      filter: {
        selectedGroup: [],
        themeFilter: [],
        tagFilter: [],
        groupFilter: [],
      }
    }
  },
  mounted(){
    this.filter = this.$store.getters['preferences/filters'];
  },
  methods : {
    handleScroll() {
      this.windowWidth = window.innerWidth;
    },
    checkCurrentRoute(name) {
      return this.$router.currentRoute.value.name === name;
    },
    toggleFilter() {
      this.$store.dispatch('showFilter');
    },
    submitFilter() {
      this.$store.dispatch('preferences/doFilter', this.filter).then(() => {
        this.$store.dispatch('showFilter');
      });
    },
    toggle(value, key) {
      const index = this.filter[key].indexOf(value);

      if (index === -1) {
        this.filter[key] = [...this.filter[key], value];
      } else {
        this.filter[key].splice(index, 1);
      }
    },
    resetFilter()
    {
      this.filter.themeFilter = [];
      this.filter.tagFilter = [];
      this.filter.groupFilter = [];
      this.$store.dispatch('preferences/resetFilters')
    },
  },
  computed: {
    ...mapGetters({
      groups : 'groups/all',
      themes: 'themes/all',
      tags: 'tags/all',
      themeFilter: 'preferences/themeFilter',
      tagFilter: 'preferences/tagFilter',
      groupFilter: 'preferences/groupFilter',
    }),


    checkFilters()
    {
      return this.filter.themeFilter.length > 0 ||
          this.filter.tagFilter.length > 0 ||
          this.filter.groupFilter.length > 0
    }
  }
}
</script>
