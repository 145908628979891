<template>
    <section :class="bgClass">
      <metainfo>
        <template v-slot:title="{ content }">{{ content }} | Scoutsmap</template>
      </metainfo>
      <SplashScreen v-if="!loaded || storeLoading" />
      <router-view :key="$route.fullPath" v-else />
    </section>

  <AllPopups />

</template>

<script>

import SplashScreen from "@/views/SplashScreen";
import AllPopups from "@/components/popups/AllPopups";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {AllPopups, SplashScreen},
  data() {
    return {
      deferredPrompt: null,
      loaded: false
    };
  },
  async mounted() {
    if(this.authenticated){
      this.$store.dispatch('user/csrfToken');
      this.$store.dispatch('preferences/fetchBoards');
    }

    this.loaded = true;

  },
  computed: {
    bgClass() {
      const meta = this.$route.meta.background;
      if (!meta || !this.loaded) {
        return 'bg-color-background';
      }

      return 'bg-color-background-' + meta;
    },
    ...mapGetters({
      storeLoading: "getLoadingState"
    })
  },
  metaInfo() {
    return {
      link: [
        { rel : 'stylesheet',  href : 'https://use.typekit.net/lix4whj.css'},
        { rel : 'apple-touch-icon',  href : require('@/assets/icons/apple-touch-icon.png')},
      ],
      meta : [
        { property : 'og:title' , content : 'Scoutsmap' },
        { property : 'og:description' , content : 'De Scoutsmap is hét doe-het-zelf-naslagwerk van de FOS-leiding. In deze handige map kan je al je scoutspaperassen - zoals activiteitenfiches, ledenlijsten, maand- en jaarprogramma’s, kampvoorbereidingen, takraadverslagen, … - een plekje geven. De map is bovendien gevuld met verschillende interessante scoutsmapartikels.' },
        { property : 'og:image' , content : window.location.origin + require('@/assets/images/social-share-image.jpg') },
        { property : 'og:locale' , content : 'nl_BE' },
        { property : 'og:type' , content : 'website' },
        { property : 'og:url' , content : window.location.href },
      ]
    }
  },
};
</script>
