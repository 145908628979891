const units = [
  "1 - Lange Wapper",
  "2 - Westhinder",
  "3 - De Wilde Eend",
  "7 - Mercator",
  "8 - 't Vloedgat",
  "9 - Wandelaar",
  "11 - De Boekaniers",
  "14 - De Faunaten",
  "15 - 't Kraaienest",
  "17 - Prins Albert",
  "21 - De Jolle",
  "24 - Tom Wilson",
  "28 - De Sperwer",
  "33 - De Navajo's",
  "36 - De Jakketoes",
  "39 - De Menapiërs",
  "45 - De Schrans",
  "78 - Tuulihaukat",
  "80 - Kludde",
  "102 - De Albatros",
  "111 - First Brussels",
  "112 - Central Brussels",
  "114 - Scouterna Bryssel",
  "120 - De Zwaluw",
  "140 - Roodbaard",
  "151 - De Koala's",
  "152 - De Kangoeroes",
  "155 - De Feniks",
  "156 - De Havik",
  "157 - De Ouistiti's",
  "163 - De Bevers",
  "190 - De Kievit",
  "191 - De Reiger",
  "192 - De Wouw",
  "200 - De Vleermuis",
  "204 - De Tortels",
  "205 - Impeesa",
  "206 - De Flamingo's",
  "207 - De Eekhoorn",
  "209 - De Vrijbuiters",
  "210 - De Dakota's",
  "213 - De Hinde",
  "218 - De Kariboes",
  "226 - De Leeuwerik",
  "233 - Durendael",
  "250 - De Grizzly",
  "260 - Pepijn",
  "263 - De Dolfijn",
  "264 - De Vlievleger",
  "281 - De Zebra's",
  "285 - Leeuwkens",
  "286 - Hermes",
  "345 - De Toekan",
  "500 - FOS Nationaal"
];

export default units;