<template>
  <Popup :title="title" v-on:exit="this.$store.dispatch('showBoardPopup')">
    <div class="boards">
      <ul>
        <li v-for="(board, i) in boards" :key="`board-${i}`">
          <BoardItem :data="board" v-on:click.prevent="toggleSaveItem(board)" popup/>
        </li>

      </ul>

      <div class="add-board">
        <button class="reset add-board-button" v-on:click="addBoard">
          <SvgInline path="icons/add-game.svg"/>
          Map aanmaken
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import BoardItem from "@/organisms/BoardItem";
import SvgInline from "@/components/SvgInline";
import {mapGetters} from "vuex";
import Popup from "@/components/popups/Popup";

export default {
  name: "BoardPopup",
  components: {Popup, SvgInline, BoardItem},
  computed: {
    ...mapGetters({
      boards: 'preferences/boards',
    }),
  },
  props : {
    item : { type : Object, required: true },
    title : { type : String, required : true},
  },
  methods : {
    toggleSaveItem(board){
      this.$store.dispatch('user/saveItem', {item : this.item.id, board : board.id})
    },
    addBoard() {
      this.$store.dispatch('showBoardPopup');
      this.$router.push({ name : 'map', params : {item : this.item.id }});
    },
    exitPopup() {
      this.$store.dispatch('showBoardPopup');
      this.$store.dispatch('setItemToSave', null);
    }
  }
}
</script>
