<template>
  <Authenticated pageId="article" v-if="article" origin class-name="detail">
    <div class="container border-bottom">
      <div class="slider">
        <swiper :slides-per-view="1" :space-between="0" class="header-img">
          <swiper-slide >
            <div class="img-container">
              <picture v-if="article.image">
                <source :srcset="article.image.webp" type="image/webp">
                <source :srcset="article.image.jpg" type="image/jpg">
                <img class="banner" :src="article.image.webp" :alt="`FOS Open Scouting : ${article.title}`">
              </picture>
            </div>
          </swiper-slide>
          <swiper-slide v-for="media in article.media" :key="`media_${media.id}`">
            <div class="img-container">
              <img class="banner" :src="media" :alt="`FOS Open Scouting : ${article.title}`">
            </div>
          </swiper-slide>
        </swiper>
        <Actions :subject="article" />
      </div>

      <div class="meta">
        <h1 ref="title">{{ article.title }}</h1>

        <b class="theme">{{ article.theme.title }}</b>

        <ul class="tags">
          <li
              v-for="tag in article.tags"
              :key="tag.id">
            <span>#{{ tag.title }}</span>
          </li>
        </ul>

        <p class="author" v-if="article.author">
          Auteur: <router-link :to="{name : 'volunteer'}">{{ article.author.title ?? 'geen auteur' }}</router-link>
        </p>
      </div>
    </div>

    <div v-if="article.summary" class="summary">
        {{ article.summary }}
    </div>


    <ToggleableContent :content="article.content" />

    <Footer v-if="article.contact" :contact="article.contact" />

    <div class="content related-content container" v-if="related.length > 0">
      <h2>Meer zoals dit</h2>

      <ul class="related">
        <li v-for="article in related" :key="`related_${article.id}`">
          <ArticleItem :data="article" />
        </li>
      </ul>
    </div>

  </Authenticated>
</template>

<script>
import Authenticated from "../layouts/Authenticated";
import {mapGetters} from "vuex";
import Actions from "../molecules/Actions";
import Footer from "../organisms/Footer";
import ArticleItem from "@/organisms/ArticleItem";
import ToggleableContent from "@/molecules/ToggleableContent";

export default {
  name: "SingleArticle",
  components: {ToggleableContent, ArticleItem, Footer, Actions, Authenticated},
  props: {
    article: {type: Object, required: true}
  },
  computed: {
    ...mapGetters({
      relatedArticles: 'articles/related',
    }),
    related() {
      return this.relatedArticles(this.article);
    },
  },
  mounted() {
    this.$store.dispatch('viewItem', this.article);
  },
  metaInfo(){
    return {
      title : this.article.title,
      meta : [
        { property : 'og:title' , content : this.article.seo.title },
        { property : 'og:description' , content : this.article.seo.description },
        { property : 'og:image' , content : this.article.seo.image },
        { property : 'og:locale' , content : 'nl_BE' },
        { property : 'og:type' , content : 'website' },
        { property : 'og:url' , content : window.location.href },
      ]
    }
  }
}
</script>
