<template>
  <Authenticated page-id="search-results">
    <div class="container">
      <button v-if="items.length > 0" class="reset search-button" v-on:click="$store.dispatch('showSearchModal')">
        <SvgInline path="icons/search.svg" />
        Opnieuw zoeken
      </button>

      <h1>Zoekresultaten</h1>

      <div v-if="items.length > 0" class="content" :class="{'hidden' : showFilter || showSearchModal }">
        <InfiniteScroll :items="items">
          <template v-slot:item="{ item }">
            <ArticleItem v-if="item.type === 'article' || item.type === 'poll' || item.type === 'event'" :data="item" />
            <GameItem v-else :data="item" />
          </template>
        </InfiniteScroll>
      </div>

      <div v-else class="no-results">
        jouw zoekopdracht levert <b>geen</b> resultaat op!

        <button class="reset search-button" v-on:click="$store.dispatch('showSearchModal')">
          <SvgInline path="icons/search.svg" />
          Opnieuw zoeken
        </button>
      </div>
    </div>
  </Authenticated>

</template>

<script>
import Authenticated from "@/layouts/Authenticated";
import SvgInline from "@/components/SvgInline";
import {mapGetters} from "vuex";
import ArticleItem from "@/organisms/ArticleItem";
import GameItem from "@/organisms/GameItem";
import InfiniteScroll from "@/molecules/InfiniteScroll";

export default {
  name: "SearchResults",
  components: {InfiniteScroll, GameItem, ArticleItem, SvgInline, Authenticated},
  computed : {
    ...mapGetters({
      items: 'searchedItems',
      showFilter : 'showFilter',
      showSearchModal : 'showSearchModal',
    }),
  },
  metaInfo() {
    return {
      title : 'Zoekresultaten'
    }
  },
}
</script>
