<template>
  <div
      class="tag bg-color-mid-blue"
      v-on:click="$emit('toggle')"
      :class="{'active' : active }
  ">
    <span>{{ tag.title }}</span>
  </div>
</template>

<script>
export default {
  name: "TagItem",
  emits : ['toggle'],
  props: {
    tag : { type: Object, required : true},
    active: {type: Boolean, required: true, default: true }
  },
}
</script>
