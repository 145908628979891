<template>
  <article
      v-if="data.media && data.media[0]"
      data-sal="zoom-in"
      data-sal-delay="0"
      data-sal-easing="ease-out"
      data-sal-duration="300"
  >
    <a :href="`/spelen/${data.slug}~${data.id}`">
      <div class="img-container" :class="{'empty' : data.media.length === 0}">
        <div v-if="this.$router.currentRoute.value.name !== 'games'" class="ribbon">

          <span>Spel</span>
          <SvgInline path="icons/shape.svg" />

        </div>
        <picture v-if="data.media.length !== 0">
          <source :srcset="data.media[0].webp" type="image/webp">
          <source :srcset="data.media[0].jpg" type="image/jpg">
          <img
              v-if="data.media.length !== 0"
              class="thumb"
              :src="data.media[0].jpg"
              alt="fos open scouting scouts"
              :data-width="data.imageSize ? data.imageSize.width : null"
              :data-height="data.imageSize ? data.imageSize.height : null"
          >
        </picture>

        <div class="lazy" v-else></div>

        <div class="overlay-options">
          <GameProperties :properties="data.properties" />
          <Actions v-if="!hideActions" :subject="data" limited/>
        </div>
      </div>
      <div class="body">
        <h3>{{ data.title }}</h3>
        <span v-if="data.theme" class="topic">{{ data.theme.title }}</span>

        <ul class="tags">
          <li v-for="tag in data.tags" :key="`tag-${tag.id}`">#{{ tag.title }}</li>
        </ul>

        <button class="save reset" :class="{'saved' : this.user.hasFavourited(this.data)}" v-if="!hideActions" v-on:click.prevent="togglePopup">
          <SvgInline path="icons/save.svg" />
        </button>
      </div>

    </a>
  </article>
</template>

<script>
import Actions from "@/molecules/Actions";
import GameProperties from "../molecules/GameProperties";
import SvgInline from "../components/SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "GameItem",
  components: {SvgInline, GameProperties, Actions},
  data() {
    return {
      showSavePopup : false,
    }
  },
  props : {
    data : { type : Object, required : true},
    hideActions: {type: Boolean, required: false, default: false},
  },
  computed: {
    ...mapGetters({
      boards: 'preferences/boards',
    }),
  },
  methods : {
    togglePopup() {
      this.authenticated ? this.toggleSavePopup() : this.toggleLoginPopup()
    },
    toggleLoginPopup() {
      this.$store.dispatch('showLoginPopup');
    },
    toggleSavePopup() {
      this.$store.dispatch('setItemToSave', this.data);
      this.$store.dispatch('showBoardPopup');
    },
    addBoard(){
      this.$router.push({ name : 'map', params : {item : this.$props.data.id }});
    },
  }
}
</script>
