<template>
  <div
      class="category-item"
      v-on:click="$emit('toggle')"
      :class="{'active' : active }"
      :style="`fill: ${theme.color}`"
  >
    <SvgInline path="icons/shape.svg" />
    <img rel="preload" :src="theme.icon" alt="icon" />
    <span :style="`color: ${fgColor}`">{{ theme.title }}</span>
  </div>
</template>

<script>
  import SvgInline from "../components/SvgInline";
  const ContrastColor = require('contrast-color');

  export default {
    name: "CategoryItem",
    components: {SvgInline},
    emits : ['toggle'],
    props: {
      theme : { type: Object, required : true},
      active: {type: Boolean, required: false, default: true }
    },
    data() {
      return {
        fgColor: '#fff',
      }
    },
    mounted() {
      const cc = new ContrastColor({
        bgColor: this.theme.color,
        threshold: 200,
        fgDarkColor: "#0047A7",
        fgLightColor: "#fff"
      });

      this.fgColor = cc.contrastColor();
    }
  }
</script>
