<template>
  <header :class="{'with-shadow' : shadow}">
    <div v-if="backButton" class="back-button">
      <router-link v-if="backButton.force" :to="{name: backButton.route}">
        <SvgInline path="icons/back-button.svg" />
        <span>{{ backButton.title }}</span>
      </router-link>
      <button v-else class="reset" v-on:click="exitDetailPage">
        <SvgInline path="icons/back-button.svg" />
        <span>{{ backButton.title }}</span>
      </button>
    </div>

    <router-link v-if="!reset" class="owl" :to="{ name : 'popular'}">
      <SvgInline path="owl-wing-open.svg"/>
    </router-link>

    <DesktopNavigation :onlyHome="reset" />

    <router-link :to="{name : 'settings'}" class="reset user-link active" :class="{'authenticated' : authenticated}">
      <SvgInline path="icons/user.svg" />
    </router-link>

    <template v-if="!reset">
      <button v-if="withFilter" class="hamburger" v-on:click="toggleFilter">
        <SvgInline path="icons/hamburger.svg" />
      </button>

      <button v-else class="close reset" v-on:click="exitDetailPage">
        <SvgInline path="icons/exit.svg" />
      </button>

    </template>

  </header>

</template>

<script>
import SvgInline from "../SvgInline";
import router from "../../router";
import DesktopNavigation from "./DesktopNavigation";
import {mapGetters} from "vuex";

export default {
  name: "Header",
  components: {DesktopNavigation, SvgInline},
  data() {
    return {
      showFilter : false,
      shadow : false,
    }
  },
  props : {
    withFilter : { type: Boolean, required : false },
    backButton : { type : Object, required: false},
    reset : { type : Boolean, required: false },
    origin : { type : Boolean, required : false}
  },
  mounted() {
    window.addEventListener('scroll', this.toggleShadow);
  },
  computed :  {
    ...mapGetters({
      originRoute : "getOrigin"
    })
  },
  methods : {
    toggleFilter() {
      this.$store.dispatch('showFilter');
    },
    exitDetailPage() {
      this.$store.commit('usePosition');
      if(this.origin) {
        return this.$router.push(this.originRoute)
      }
      if(window.history.length){
        this.$router.back();
      } else {
        this.$router.push({ name : 'home'})
      }
    },
    toggleShadow(e){
      this.shadow = e.currentTarget.scrollY > 72;
    }
  }
}
</script>
