export default {
  namespaced: true,
  state: {
    searchTerm : '',
    selectedGroup: [],
    themeSearch: [],
    tagSearch: [],
    groupSearch: [],

  },
  mutations: {
    resetFilters(state){
      state.searchTerm = '';
      state.selectedGroup = [];
      state.themeSearch = [];
      state.tagSearch = [];
      state.groupSearch = [];
    },
    toggleThemeSearch(state, id) {
      const index = state.themeSearch.indexOf(id);

      if (index === -1) {
        state.themeSearch = [...state.themeSearch, id];
      } else {
        state.themeSearch.splice(index, 1);
      }
    },
    toggleGroupSearch(state, id) {
      if(state.groupSearch){
        const index = state.groupSearch.indexOf(id);

        if (index === -1) {
          state.groupSearch = [...state.groupSearch, id];
        } else {
          state.groupSearch.splice(index, 1);
        }
      } else {
        state.groupSearch.push(id);
      }

    },
    setSelectedGroup(state, group) {
      state.selectedGroup = group;
    },
    toggleTagGroup(state, id) {
      const index = state.tagSearch.indexOf(id);

      if (index === -1) {
        state.tagSearch = [...state.tagSearch, id];
      } else {
        state.tagSearch.splice(index, 1);
      }
    },
    setSearchTerm(state, search) {
      state.searchTerm = search;
    },
    setThemeSearch(state, search) {
      state.themeSearch = search;
    },
    setGroupSearch(state, search) {
      state.groupSearch = search;
    },
    setTagSearch(state, search) {
      state.tagSearch = search;
    }
  },
  getters: {
    themeSearch(state) {
      return state.themeSearch;
    },
    groupSearch(state) {
      return state.groupSearch;
    },
    tagSearch(state) {
      return state.tagSearch;
    },
    search(state) {
      return {
        searchTerm : state.searchTerm,
        selectedGroup: state.selectedGroup,
        groupSearch: state.groupSearch,
        tagSearch: state.tagSearch,
        themeSearch: state.themeSearch
      }
    },
  },
  actions: {
    async doSearch({ commit }, search) {
      await commit('setSearchTerm', search.searchTerm);
      await commit('setGroupSearch', search.groupSearch);
      await commit('setTagSearch', search.tagSearch);
      await commit('setThemeSearch', search.themeSearch);
      await commit('setSelectedGroup', search.selectedGroup);
    },
    async toggleThemeSearch({commit, rootState, state}, theme) {
      await commit('toggleThemeSearch', theme.id);
    },
    async toggleGroupSearch({commit, rootState, state}, group) {
      await commit('toggleGroupSearch', group.id);
    },
    async toggleTagSearch({commit, rootState, state}, tag) {
      await commit('toggleGroupSearch', tag.id);
    },
    async resetSearch({commit, rootState, state}) {
      await commit('resetSearch');
    },
  },
};

