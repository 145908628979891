<template>
  <div class="step-2">
    <p>{{ game.name }}</p>
    <span class="big-text">
      Type spel
    </span>

    <div class="types">
      <div class="button-wrap type-short">
        <button v-on:click="proceed('short')" class="reset">
          <SvgInline path="icons/shape.svg"/>
          <span class="title">Kort spel</span>
        </button>
      </div>

      <div class="button-wrap type-activity">
        <button v-on:click="proceed('activity')" class="reset">
          <SvgInline path="icons/shape.svg"/>
          <span class="title">Activiteit</span>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import SvgInline from "../../SvgInline";
import {mapGetters} from "vuex";

export default {
  name: "Step2",
  components: {SvgInline},
  props : ['modelValue'],
  emits : ['update:modelValue', 'game-process'],
  computed: {
    ...mapGetters({
      game : 'newGame',
    })
  },
  methods : {
    proceed(type) {
      this.game.type = type;
      this.$store.dispatch('updateGame', this.game);
      this.$emit("game-process");
    }
  }

}
</script>

<style scoped>

</style>
