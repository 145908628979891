<template>
  <Authenticated
      page-id="account-settings"
      class-name="settings"
      :back-button="{ route : 'settings', title : 'Account Instellingen'}"
      reset
  >

  </Authenticated>

</template>

<script>
import Authenticated from "../../layouts/Authenticated";

export default {
  name: "AccountSettings",
  components: {Authenticated}
}
</script>