<template>
  <div class="not-found">
    <SvgInline path="owl-wing-open-right.svg" />
    <h1>Oeps!</h1>
    Pagina niet gevonden
    <router-link :to="{ name : 'popular' }" class="btn btn-color-pink">
      Ga terug naar home
    </router-link>
  </div>
</template>

<script>
import SvgInline from "@/components/SvgInline";
export default {
  name: "NotFound",
  components: {SvgInline}
}
</script>