<template>
  <Popup :title="title"
         v-on:exit="this.$store.dispatch('showLoginPopup')">
    <p v-if="text">{{ text }}</p>
    <button v-on:click="goTo('account')" class="btn btn-color-kaki">Aanmelden</button>
  </Popup>
</template>

<script>
import Popup from "@/components/popups/Popup";

export default {
  name: "LoginPopup",
  components: {Popup},
  props : {
    text : { type : String, required : false },
    title : { type : String, required : true},
  },
  methods : {
    goTo(link){
      this.$store.dispatch('showLoginPopup');
      this.$router.push({name : link});
    }
  }
}
</script>
